<template>
  <div>
    <div
      class="wrap"
      :class="[isMobile ? 'mWrap' : 'pcWrap', isInternal ? '' : 'out']"
    >
      <div class="body">
        <div class="m_topBox" v-if="isMobile">
          <div class="m_topInfo">
            <img src="../../assets/img/fund/img/m_bg.png" alt="" class="bg" />
            <!-- <img src="../../assets/img/fund/img/m_bg.png" alt="" class="bg" /> -->
            <div class="title1">
              {{ translateTitle("4E平台设立")
              }}<b>{{ translateTitle("1亿") }}</b
              >{{ translateTitle("美元") }}
            </div>
            <div class="title2">{{ translateTitle("风险保护基金") }}</div>
            <div class="title3">
              {{ translateTitle("构筑资产安全坚实防线") }}
            </div>
            <div class="title4">
              {{
                translateTitle(
                  "作为业界的领军者，始终将用户资产安全放在首位，不断探索和实践更为安全可靠的管理措施"
                )
              }}
            </div>
            <div class="detailBox">
              <div class="item">
                <span class="mainText"> {{ translateTitle("冷热处理") }}</span>
                <span class="subText">{{ translateTitle("安全管理") }}</span>
              </div>
              <div class="item">
                <span class="mainText">{{ translateTitle("1亿美金") }}</span>
                <span class="subText">{{ translateTitle("保护基金") }}</span>
              </div>
              <div class="item">
                <span class="mainText">{{ translateTitle("38位") }}</span>
                <span class="subText">{{ translateTitle("全球排名") }}</span>
              </div>
              <div class="item">
                <span class="mainText">{{ translateTitle("500亿美元+") }}</span>
                <span class="subText">{{
                  translateTitle("24小时交易金额")
                }}</span>
              </div>
              <div class="item">
                <span class="mainText">{{ translateTitle("500万+") }}</span>
                <span class="subText">{{ translateTitle("用户数") }}</span>
              </div>
              <div class="item">
                <span class="mainText">{{ translateTitle("20+") }}</span>
                <span class="subText">{{ translateTitle("覆盖国家") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="fullBox" v-else>
          <div class="topBox">
            <div class="topInfo">
              <div class="title1">
                {{ translateTitle("4E平台设立")
                }}<b>{{ translateTitle("1亿") }}</b
                >{{ translateTitle("美元") }}
              </div>
              <div class="title2">{{ translateTitle("风险保护基金") }}</div>
              <div class="title3">
                {{ translateTitle("构筑资产安全坚实防线") }}
              </div>
              <div class="title4">
                {{
                  translateTitle(
                    "作为业界的领军者，始终将用户资产安全放在首位，不断探索和实践更为安全可靠的管理措施"
                  )
                }}
              </div>
            </div>
          </div>
          <div class="detailBox">
            <div class="item">
              <span>{{ translateTitle("冷热处理") }}</span>
              <span>{{ translateTitle("安全管理") }}</span>
            </div>
            <div class="item">
              <span>{{ translateTitle("1亿美金") }}</span>
              <span>{{ translateTitle("保护基金") }}</span>
            </div>
            <div class="item">
              <span>{{ translateTitle("38位") }}</span>
              <span>{{ translateTitle("全球排名") }}</span>
            </div>
            <div class="item">
              <span>{{ translateTitle("500亿美元+") }}</span>
              <span>{{ translateTitle("24小时交易金额") }}</span>
            </div>
            <div class="item">
              <span>{{ translateTitle("500万+") }}</span>
              <span>{{ translateTitle("用户数") }}</span>
            </div>
            <div class="item">
              <span>{{ translateTitle("20+") }}</span>
              <span>{{ translateTitle("覆盖国家") }}</span>
            </div>
          </div>
        </div>
        <div class="bottomBoxContainer">
          <!-- <div class="bgBox" v-if="isMobile"></div>
        <div class="bgBox" v-else></div> -->
          <div class="box1" v-if="isMobile">
            <div class="title">{{ translateTitle("严格的资产安全管理") }}</div>
            <div class="subTitle">
              {{
                translateTitle(
                  "4E平台一直以来都以保护用户资产为第一目标，对加密货币严格实施冷热分离管理，将绝大多数的用户加密货币存放于多重签名冷钱包中，这种管理方式大幅降低了资产被盗取的风险，同时也在以往的运营中证明了其高效性和可靠性，为平台积累了良好的安全记录和声誉。"
                )
              }}
            </div>
            <div class="list">
              <div class="item">
                <img
                  v-if="isDarkMode"
                  src="../../assets/img/fund/img/img1.png"
                  alt=""
                />
                <img
                  v-if="!isDarkMode"
                  src="../../assets/img/fund/img/light_img1.png"
                  alt=""
                />
                <span>{{
                  translateTitle("对加密货币严格实施冷热分离管理")
                }}</span>
              </div>
              <div class="item">
                <img
                  v-if="isDarkMode"
                  src="../../assets/img/fund/img/img2.png"
                  alt=""
                />
                <img
                  v-if="!isDarkMode"
                  src="../../assets/img/fund/img/light_img2.png"
                  alt=""
                />
                <span>{{
                  translateTitle("大多数的用户加密货币存放于多重签名冷钱包中")
                }}</span>
              </div>
            </div>
          </div>
          <div class="box1" v-else>
            <div class="title">{{ translateTitle("严格的资产安全管理") }}</div>
            <div class="subTitle">
              {{
                translateTitle(
                  "4E平台一直以来都以保护用户资产为第一目标，对加密货币严格实施冷热分离管理，将绝大多数的用户加密货币存放于多重签名冷钱包中，这种管理方式大幅降低了资产被盗取的风险，同时也在以往的运营中证明了其高效性和可靠性，为平台积累了良好的安全记录和声誉。"
                )
              }}
            </div>
            <div class="list">
              <div class="item">
                <img
                  v-if="isDarkMode"
                  src="../../assets/img/fund/img/img1.png"
                  alt=""
                />
                <img
                  v-if="!isDarkMode"
                  src="../../assets/img/fund/img/light_img1.png"
                  alt=""
                />
                <span>{{
                  translateTitle("对加密货币严格实施冷热分离管理")
                }}</span>
              </div>
              <div class="item">
                <img
                  v-if="isDarkMode"
                  src="../../assets/img/fund/img/img2.png"
                  alt=""
                />
                <img
                  v-if="!isDarkMode"
                  src="../../assets/img/fund/img/light_img2.png"
                  alt=""
                />
                <span>{{
                  translateTitle("大多数的用户加密货币存放于多重签名冷钱包中")
                }}</span>
              </div>
            </div>
          </div>
          <div class="box2" v-if="isMobile">
            <div class="title">
              {{ translateTitle("设立1亿美元风险保护基金") }}
            </div>
            <div class="subTitle">
              {{
                translateTitle(
                  "为应对可能出现的极端突发事故，构筑另一道安全防线。"
                )
              }}
            </div>
            <div class="list">
              <div class="item">
                <div class="mainText">
                  {{ translateTitle("设置风险保护基金") }}
                </div>
                <div class="subText">
                  {{
                    translateTitle(
                      "通过将平台的部分收益分配到该基金中，专项用于各种潜在事故，在需要时随时调用，为交易者的资产提供额外的安全保障。"
                    )
                  }}
                </div>
              </div>
              <div class="item">
                <div class="mainText">
                  {{ translateTitle("初具规模已达到1亿美元") }}
                </div>
                <div class="subText">
                  {{
                    translateTitle(
                      "已经远超存放于平台热钱包中的加密货币，足以支持4E用户的资产安全，后续平台将持续支持该基金稳健增长。"
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="hashBox">
              <div class="scrollBox">
                <div class="hashList">
                  <div
                    class="item"
                    v-for="(item, index) in netList"
                    :class="{ active: index === activeHash }"
                    v-bind:key="index"
                    @click="changeHash(item, index)"
                  >
                    {{ item.hash }}
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="text">NET WORTH</div>
                <div class="amount">${{ activeItem.balance }}</div>
              </div>
            </div>
            <img
              src="../../assets/img/fund/img/btn.png"
              alt=""
              class="btnImg"
              @click="goLink"
              v-if="isDarkMode"
            />
            <img
              src="../../assets/img/fund/img/light_btn.png"
              alt=""
              class="btnImg"
              @click="goLink"
              v-else
            />
          </div>
          <div class="box2" v-else>
            <div class="title">
              {{ translateTitle("设立") }}<b>{{ translateTitle("1亿") }}</b
              >{{ translateTitle("美元风险保护基金") }}
            </div>
            <div class="subTitle">
              {{
                translateTitle(
                  "为应对可能出现的极端突发事故，构筑另一道安全防线。"
                )
              }}
            </div>
            <div class="list">
              <div class="item">
                <div class="innerBox">
                  <div class="mainText">
                    {{ translateTitle("设置风险保护基金") }}
                  </div>
                  <div class="subText">
                    {{
                      translateTitle(
                        "通过将平台的部分收益分配到该基金中，专项用于各种潜在事故，在需要时随时调用，为交易者的资产提供额外的安全保障。"
                      )
                    }}
                  </div>
                </div>
                <div class="outBox"></div>
              </div>
              <div class="item">
                <div class="innerBox">
                  <div class="mainText">
                    {{ translateTitle("初具规模已达到1亿美元") }}
                  </div>
                  <div class="subText">
                    {{
                      translateTitle(
                        "已经远超存放于平台热钱包中的加密货币，足以支持4E用户的资产安全，后续平台将持续支持该基金稳健增长。"
                      )
                    }}
                  </div>
                </div>
                <div class="outBox"></div>
              </div>
            </div>
            <div class="hashBox">
              <img
                src="../../assets/img/fund/img/light_leftImg.png"
                alt=""
                class="leftImg"
                v-if="isDarkMode"
              />
              <img
                src="../../assets/img/fund/img/leftImg.png"
                alt=""
                class="leftImg"
                v-else
              />
              <img
                src="../../assets/img/fund/img/light_rightImg.png"
                alt=""
                class="rightImg"
                v-if="isDarkMode"
              />
              <img
                src="../../assets/img/fund/img/rightImg.png"
                alt=""
                class="rightImg"
                v-else
              />
              <div class="scrollBox">
                <div class="hashList">
                  <div
                    class="item"
                    v-for="(item, index) in netList"
                    :class="{ active: index === activeHash }"
                    v-bind:key="index"
                    @click="changeHash(item, index)"
                  >
                    {{ item.hash }}
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="hashValue">
                  {{ netList[activeHash].originHash }}
                  <img
                    src="../../assets/img/fund/img/copy.png"
                    alt=""
                    class="hashCopy"
                    v-clipboard:copy="netList[activeHash].originHash"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  />
                </div>
                <div class="text">NET WORTH</div>
                <div class="amount">${{ activeItem.balance }}</div>
              </div>
            </div>
            <div class="view-detail-btn">
              <span class="view-detail-btn-text" @click="goLink">{{
                translateTitle("点击查看")
              }}</span>
            </div>
            <!-- <img
            src="../../assets/img/fund/img/btn.png"
            alt=""
            class="btnImg"
            @click="goLink"
            v-if="isDarkMode"
          />
          <img
            src="../../assets/img/fund/img/light_btn.png"
            alt=""
            class="btnImg"
            @click="goLink"
            v-else
          /> -->
          </div>
          <div class="box3" v-if="isMobile">
            <div class="title">
              {{ translateTitle("极端事件全额先行赔付") }}
            </div>
            <div class="innerBox">
              <div class="topText">
                {{
                  translateTitle(
                    "与依赖外部融资的传统基金不同，4E平台1亿美元风险保护基金完全是平台自有资产，存放于独立地址，这一独特性赋予了该基金完全的自主权和效率，也是平台对提供安全、可信交易环境的坚定承诺。"
                  )
                }}
              </div>
              <div class="line"></div>
              <div class="bottomText">
                {{
                  translateTitle(
                    "4E平台的1亿美元风险保护基金不仅是一种象征，更是一种实际行动。对于任何非用户自身原因造成的资产安全损失，4E平台将快速从安全备付金中提取资金，对用户实施全额先行赔付。这种承诺，是平台的责任和担当，也是对自身实力的自信，和对用户权益的尊重和保护，是在长远中构建信任的基石。用户可以放心地在平台上进行交易，无需担心因平台资金问题而影响到自己的资产安全。"
                  )
                }}
              </div>
              <img
                src="../../assets/img/fund/img/m_box3_img.png"
                alt=""
                class="img"
              />
            </div>
          </div>
          <div class="box3" v-else>
            <div class="title">
              {{ translateTitle("极端事件全额先行赔付") }}
            </div>
            <div class="subTitle">
              {{
                translateTitle(
                  "与依赖外部融资的传统基金不同，4E平台1亿美元风险保护基金完全是平台自有资产，存放于独立地址，这一独特性赋予了该基金完全的自主权和效率，也是平台对提供安全、可信交易环境的坚定承诺。"
                )
              }}
            </div>
            <div class="line"></div>
            <div class="desc">
              <span>
                {{
                  translateTitle(
                    "4E平台的1亿美元风险保护基金不仅是一种象征，更是一种实际行动。对于任何非用户自身原因造成的资产安全损失，4E平台将快速从安全备付金中提取资金，对用户实施全额先行赔付。这种承诺，是平台的责任和担当，也是对自身实力的自信，和对用户权益的尊重和保护，是在长远中构建信任的基石。用户可以放心地在平台上进行交易，无需担心因平台资金问题而影响到自己的资产安全。"
                  )
                }}
              </span>
              <img src="../../assets/img/fund/img/box3_img.png" />
            </div>
          </div>
          <div class="box4-container">
            <div class="box4" v-if="isMobile">
              <div class="title">
                {{ translateTitle("实力造就传奇") }}<br />{{
                  translateTitle("4E成冠军之选")
                }}
              </div>
              <div class="text">
                {{
                  translateTitle(
                    "4E始终坚持行业最高安全标准，在加速业务布局的同时，通过一系创新性产品，4E自去年上线以来，实现了业内罕见的高速发展。"
                  )
                }}
              </div>
              <div class="subTitle">{{ translateTitle("数据实力") }}</div>
              <div class="text">
                {{
                  translateTitle(
                    "此前非小号数据显示，4E全球排名已上升至第38位，24小时交易量达500.22亿美元。在越南地区，4E在AppStore下载量排名第一。目前4E平台全球用户超过500万，覆盖全球20多个国家和地区，以安全卓越的创新产品和全球服务网络赢得了业界赞誉，更在海外获得了国家级的认可。"
                  )
                }}
              </div>
              <div class="list">
                <div class="item item1">
                  <div class="mini">
                    <div class="mainText">{{ translateTitle("38位") }}</div>
                    <div class="subText">{{ translateTitle("全球排名") }}</div>
                  </div>
                  <div class="big">
                    <div class="mainText">
                      {{ translateTitle("500.22亿元") }}
                    </div>
                    <div class="subText">
                      {{ translateTitle("24小时交易量") }}
                    </div>
                  </div>
                </div>
                <div class="item item2">
                  <div class="mini">
                    <div class="mainText">1</div>
                    <div class="subText">
                      {{ translateTitle("越南下载量排名") }}
                    </div>
                  </div>
                  <div class="big">
                    <div class="mainText">{{ translateTitle("500万+") }}</div>
                    <div class="subText">{{ translateTitle("全球用户") }}</div>
                  </div>
                </div>
                <div class="item3">
                  <div class="mainText">20+</div>
                  <div class="subText">{{ translateTitle("覆盖国家") }}</div>
                  <img src="../../assets/img/fund/img/arrowImg.png" alt="" />
                </div>
              </div>
            </div>
            <div class="box4" v-else>
              <div class="title">
                {{ translateTitle("实力造就传奇，4E成冠军之选") }}
              </div>
              <div class="subTitle">
                {{
                  translateTitle(
                    "4E始终坚持行业最高安全标准，在加速业务布局的同时，通过一系创新性产品，4E自去年上线以来，实现了业内罕见的高速发展。"
                  )
                }}
              </div>
              <div class="outMainBox">
                <div class="mainBox">
                  <div class="innerBox">
                    <div class="left">
                      <div class="mainText">
                        {{ translateTitle("数据实力") }}
                      </div>
                      <div class="subText">
                        {{
                          translateTitle(
                            "此前非小号数据显示，4E全球排名已上升至第38位，24小时交易量达500.22亿美元。在越南地区，4E在AppStore下载量排名第一。目前4E平台全球用户超过500万，覆盖全球20多个国家和地区，以安全卓越的创新产品和全球服务网络赢得了业界赞誉，更在海外获得了国家级的认可。"
                          )
                        }}
                      </div>
                    </div>
                    <div class="right">
                      <div class="item1 item">
                        <div class="mini">
                          <span>{{ translateTitle("38位") }}</span>
                          <span>{{ translateTitle("全球排名") }}</span>
                        </div>
                        <div class="big">
                          <span>{{ translateTitle("500.22亿元") }}</span>
                          <span>{{ translateTitle("24小时交易量") }}</span>
                        </div>
                      </div>
                      <div class="item2 item">
                        <div class="mini">
                          <span>1</span>
                          <span>{{ translateTitle("越南下载量排名") }}</span>
                        </div>
                        <div class="big">
                          <span>{{ translateTitle("500万+") }}</span>
                          <span>{{ translateTitle("全球用户") }}</span>
                        </div>
                      </div>
                      <div class="item3">
                        <span>20+</span>
                        <span>{{ translateTitle("覆盖国家") }}</span>
                        <img
                          src="../../assets/img/fund/img/arrowImg.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" v-if="isMobile">
            <div class="footer-top">
              <div class="title">{{ translateTitle("4E携手AFA") }}</div>
              <div class="text">
                {{
                  translateTitle(
                    "3月份，4E宣布与阿根廷国家足球队达成战略合作，成为阿根廷国家队全球合作伙伴。作为世界顶级球队，阿根廷国家队对于合作伙伴的选择向来以严谨著称。4E平台能够得到认可，不仅证明了平台在加密货币领域的领先性，更显示了其在多方面的实力和可靠性。这样的合作对于4E平台来说，不仅是一份荣誉，更是对平台安全性、专业性和可靠性的有力证明。"
                  )
                }}
              </div>
            </div>
            <div class="footer-mid">
              <img src="../../assets/img/fund/img/footer_img1.png" alt="" />
            </div>
            <div class="footer-btm">
              <img src="../../assets/img/fund/img/m_footer_bg.png" alt="" />
              <div class="mainText">{{ translateTitle("结语") }}</div>
              <div class="subText">
                {{
                  translateTitle(
                    "4E平台设立1亿美元的风险保护基金，是对用户资产安全承诺的具体体现，也是对行业安全标准的一次重要提升。这一举措不仅加深了用户对平台的信任感，也为整个加密货币交易市场树立了良好的榜样。未来，4E平台将继续坚持用户至上的原则，不断提升服务质量，为用户打造一个更加安全、可靠的加密货币交易平台。"
                  )
                }}
              </div>
            </div>
          </div>
          <div class="footer" v-else>
            <div class="footer-top">
              <div class="left">
                <img
                  src="../../assets/img/fund/img/footer_img1.png"
                  alt=""
                  class="bigImg"
                />
                <img
                  v-if="isDarkMode"
                  src="../../assets/img/fund/img/footer_img2.png"
                  alt=""
                  class="smallImg"
                />
                <img
                  v-else
                  src="../../assets/img/fund/img/footer_img2-light.png"
                  alt=""
                  class="smallImg"
                />
              </div>
              <div class="right">
                <div class="mainText">{{ translateTitle("4E携手AFA") }}</div>
                <div class="subText">
                  {{
                    translateTitle(
                      "3月份，4E宣布与阿根廷国家足球队达成战略合作，成为阿根廷国家队全球合作伙伴。作为世界顶级球队，阿根廷国家队对于合作伙伴的选择向来以严谨著称。4E平台能够得到认可，不仅证明了平台在加密货币领域的领先性，更显示了其在多方面的实力和可靠性。这样的合作对于4E平台来说，不仅是一份荣誉，更是对平台安全性、专业性和可靠性的有力证明。"
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="footer-bottom">
              <div class="mainTitle">{{ translateTitle("结语") }}</div>
              <div class="text">
                {{
                  translateTitle(
                    "4E平台设立1亿美元的风险保护基金，是对用户资产安全承诺的具体体现，也是对行业安全标准的一次重要提升。这一举措不仅加深了用户对平台的信任感，也为整个加密货币交易市场树立了良好的榜样。未来，4E平台将继续坚持用户至上的原则，不断提升服务质量，为用户打造一个更加安全、可靠的加密货币交易平台。"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/components/common/commonMixin.vue";
import bannerEn from "@/assets/img/fund/img/banner_en.png";
import bannerVi from "@/assets/img/fund/img/banner_vi.png";
import bannerZh from "@/assets/img/fund/img/banner_zh.png";
import { showMessage } from "@/components/dialog/message";
export default {
  mixins: [commonMixin],
  data() {
    return {
      isMobile: false,
      isInternal: true,
      activeDate: 1,
      currentIndex: 0,
      activeHash: 0,
      activeItem: "",
      colorModel: 1,
      netList: [
        {
          hash: "0x131031be34b0b232024fe4be883f44eb5255939d",
          balance: "6,182,024",
          chain: "BSC",
        },
        {
          hash: "0x1bf0aa215dab195f21372105f53661e46f962ff3",
          balance: "27,636,927",
          chain: "ETHEREUM",
        },
        {
          hash: "0x025cf6ce14657169f0b123ddf877da0b95a7d243",
          balance: "497,575",
          chain: "POLYGON",
        },
        {
          hash: "TEfuqeTx25jxWMSic93bzms7kPZsgPRSXU",
          balance: "10,002,791",
          chain: "TRON",
        },
        {
          hash: "0x0ce7eefb9f862aa0374ee7bbc4d8a0fc2c651517",
          balance: "80407147",
          chain: "ETHEREUM",
        },
        {
          hash: "0x0ce92d3a15908b53371ff1afcae800f28142250c",
          balance: "34,698,239",
          chain: "ETHEREUM",
        },
        {
          hash: "0x6db133e840376555a5ad5c1d7616872ef57e7f13",
          balance: "39,555,725",
          chain: "BSC",
        },
      ],
    };
  },
  created() {
    // this.setLang();
    this.checkMobile();
    window.onresize = this.checkMobile;
    // this.mockUrl();
    this.netList.forEach((item) => {
      let hash = item.hash;
      let newHash = hash.slice(0, 8) + "..." + hash.slice(-7);
      item.originHash = hash;
      item.hash = newHash;
    });
    this.activeItem = this.netList[0];
  },
  mounted() {
    this.$nextTick(() => {
      this.updateImgSrc();
    });
  },
  methods: {
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle(`内容复制成功`),
        type: "success",
        offset: 80,
      });
    },
    // 复制失败
    copyError() {
      this.$message({
        message: this.translateTitle(`内容复制失败`),
        type: "error",
        offset: 80,
      });
    },
    changeHash(item, index) {
      let listDom = document.querySelector(".box2 .hashBox .hashList");
      let itemDom = document.querySelector(".box2 .hashBox .hashList .item");
      let left = index * (itemDom.clientWidth + 10);
      let width = listDom.clientWidth / 2;
      if (left > width) {
        listDom.scrollTo(left - width, 0);
      } else {
        listDom.scrollTo(0, 0);
      }
      this.activeHash = index;
      this.activeItem = item;
    },
    updateImgSrc() {
      const lang = this.$store.state.user.language;
      const $el = document.querySelector(
        "div.footer > div.footer-top > div.left > img.bigImg"
      );
      if (!$el) return;
      const map = {
        vi: bannerVi,
        en: bannerEn,
        zh_CN: bannerZh,
      };
      $el.src = map[lang] || bannerEn;
    },
    setLang() {
      let lang = (navigator.browserLanuage || navigator.language).toLowerCase();
      this.lang =
        lang.indexOf("vi") > -1
          ? "vi"
          : lang.indexOf("en") > -1
          ? "en"
          : lang.indexOf("es") > -1
          ? "es"
          : lang.indexOf("pt") > -1
          ? "pt"
          : lang.indexOf("th") > -1
          ? "th"
          : "zh";
    },
    checkMobile() {
      const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      const oldVal = localStorage.isMobile;
      const getArr = Agents.filter((i) => navigator.userAgent.includes(i));
      const isMobile =
        getArr.length || document.body.clientWidth < 768 ? true : false;
      this.isMobile = isMobile;
      document.getElementById("app").className = `${
        isMobile ? "mApp" : "pApp"
      } ${this.lang}App`;
      setTimeout(() => {
        if (oldVal == undefined || Boolean(Number(oldVal)) != isMobile) {
          localStorage.isMobile = isMobile ? 1 : 0;
        }
      }, 0);
    },
    setMoney(money) {
      return (money + "").replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
        return match + ",";
      });
    },
    back() {
      window.open("http://www.eeee.com");
    },
    jumpTg() {
      const group1 = this.parseQueryParams("group1");
      window.open(group1 || tgMap[this.lang]);
    },
    jumpChat() {
      const group2 = this.parseQueryParams("group2");
      window.open(group2 || chatMap[this.lang]);
    },
    setText(key, isBreak = false) {
      return langMap[this.lang][key];
    },
    openRule() {
      this.showRule = true;
    },
    close() {
      this.showRule = false;
      this.showChat = false;
    },
    join() {
      const ref = this.parseQueryParams("ref");
      let domain = location.origin;
      if (ref) {
        if (!domain.startsWith("http")) domain = "https://download.eeeedex.com";
        window.open(`${domain}/?ref=${ref}&forward_url=2&page=1`);
      } else {
        if (!domain.startsWith("http")) domain = "https://download.eeeedex.com";
        window.open(`${domain}/?ref=&forward_url=2&page=1`);
      }
    },
    goLink() {
      const activeItem = this.netList[this.activeHash];
      const url = `https://portfolio.nansen.ai/dashboard/${activeItem.originHash}?chain=${activeItem.chain}`;
      window.open(url);
    },

    more() {
      /**
       * 跳转地址中
https://download.eeeedex.com/activity/2023/active181/activity1/
英
https://download.eeeedex.com/activity/2023/active181/activity1_en/
越南
https://download.eeeedex.com/activity/2023/active181/activity1_vi/
       */
      window.open(
        "https://download.eeeedex.com/activity/2023/active181/activity1/"
      );
    },
    service() {
      window.open("https://direct.lc.chat/15392850");
    },
    community() {
      let url =
        "https://download.eeeedex.com/activity/2023/community/guide/index.html";
      const group = this.parseQueryParams("group1");
      if (group) url = group;
      window.open(url);
    },
    parseQueryParams(key) {
      var params = {};
      location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (match, key, value) {
          params[key] = value;
        }
      );
      return params[key] || "";
    },
    openChat(type) {
      this.showChat = true;
      this.isQQ = type == "qq";
      this.copyText();
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
    copyText() {
      let textToCopy = this.isQQ ? "215148260" : "f231238"; // 替换为您要复制的文本
      let textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
    },
    mockUrl() {
      const list = ["ABFVOG"];
      const linkMap = {
        zh_CN: {
          url: "https://download.eeeedex.com/activity/2023/btv/active",
          suffix: "",
        },
        // en_US: { url: "https://download.eeeedex.com/activity/2023/btv/activity", suffix: "_en" },
        // vi_VN: { url: "https://download.eeeedex.com/activity/2023/btv/activity", suffix: "_vi" },
      };
      const res = [];
      for (let index = 0; index < list.length; index++) {
        const langItem = linkMap.zh_CN;
        res.push(
          `${langItem.url}1${langItem.suffix}?ref=${list[index]}&group1=https://t.me/Official4e_Chinese_News&group2=https://www.kuzh2.com?qrCode=NTY7NHHT&imQrCodeType=1&IdCode=083AF10E09702E90E151415855103F6D`
        );
        res.push(
          `${langItem.url}1${langItem.suffix}?ref=${list[index]}&group1=https://t.me/Official_4e_News`
        );
        res.push(
          `${langItem.url}1${langItem.suffix}?ref=${list[index]}&group1=https://t.me/Vinacoinvietnam&group2=https://zalo.me/g/rhgdvk262`
        );
      }
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped >
* {
  user-select: none;
  word-break: break-word;
}
:root[theme="dark"] {
  .view-detail-btn-text {
    color: #000;
    background: #fff;
  }
}
.view-detail-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  &-text {
    width: 198px;

    color: #fff;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(141, 152, 241, 0.09);
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.36px;
    border-radius: 100px;
    border: 8px solid rgba(141, 152, 241, 0.09);
    background: #386bff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 2px 0;
    text-align: center;
    cursor: pointer;
  }
}
.vi .pcWrap .topBox .topInfo .title1 {
  font-size: 40px;
}
.vi .pcWrap .topBox .topInfo .title2 {
  font-size: 40px;
  line-height: 40px;
}
.vi .pcWrap .topBox .topInfo .title4 {
  max-width: 895px;
}

.vi {
  .pcWrap .footer .footer-top img:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    background-image: url("../../assets/img/fund/img/banner_vi.png");
  }
  /* position: relative;
    width: 100%;
    min-width: 1200px;
    background: url("../../assets/img/fund/img/banner_vi.png") no-repeat
      center/cover;
    padding-bottom: 75px; */
  /* } */
}
.bottomBoxContainer {
  /* position: relative;
  top: -221px; */
}
#app:not(.zh_CN, .zh_HK) {
  .pcWrap .topBox {
    /* width: 1000px; */
  }
  .bottomBoxContainer {
    /* padding-top: 221px; */
  }
  .pcWrap .topBox .topInfo .title1 {
    max-width: 888px;
  }
  .pcWrap .topBox .topInfo .title4 {
    max-width: 800px;
  }
  .pcWrap .topBox .topInfo .title3 {
    font-size: 26px !important;
  }
  .pcWrap .fullBox .detailBox .item:not(:last-child) {
    margin-right: 20px !important;
  }

  .pcWrap .fullBox .detailBox .item span:first-of-type {
    font-size: 20px !important;
  }
  .pcWrap .fullBox .detailBox .item span:last-of-type {
    font-size: 14px !important;
  }
  .pcWrap .box4 .innerBox .right .item .mini span:first-of-type,
  .pcWrap .box4 .innerBox .right .item .big span:first-of-type {
    font-size: 22px !important;
  }
  .pcWrap .box4 .title {
    font-size: 34px !important;
  }
  .box4-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .box4 {
      /* width: 1200px; */
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        width: 1200px;
      }
      .subTitle {
        width: 1200px;
      }
    }
  }
}

.pcWrap {
  min-width: 1200px;
  background: #000;
  overflow: hidden;
}
.wrap {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-family: PingFang SC, "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

/* PC */
.pcWrap {
  box-sizing: border-box;
}

.pcWrap .body {
  position: relative;
}
.pcWrap .fullBox {
  position: relative;
  width: 100%;
  min-width: 1200px;
  height: 934px;
  background: url("../../assets/img/fund/img/bg.png") no-repeat 50% / cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 100px 0 100px 0;
}
.pcWrap .topBox {
  /* position: relative; */
  /* height: 620px; */
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.pcWrap .topBox .topInfo {
  /* padding-top: 130px; */
}
.pcWrap .topBox .topInfo .title1 {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 76px; /* 135.714% */
  letter-spacing: 1.12px;
}
.pcWrap .topBox .topInfo .title1 b {
  color: #ffa101;
  margin: 0 10px;
}
.pcWrap .topBox .topInfo .title2 {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 76px; /* 135.714% */
  letter-spacing: 1.12px;
  margin-bottom: 13px;
}
.pcWrap .topBox .topInfo .title3 {
  color: #aeb4d2;
  font-family: "PingFang SC";
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 136.364% */
  letter-spacing: 0.88px;
  margin-bottom: 17px;
}
.pcWrap .topBox .topInfo .title4 {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.pcWrap .fullBox .detailBox {
  /* position: absolute; */
  width: 1200px;
  /* min-height: 120px; */
  padding: 32px 47px;
  /* left: 50%;
  bottom: -60px;*/
  transform: translateY(50%);
  border-radius: 4px;
  background: #161a27;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 9;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
.pcWrap .fullBox .detailBox .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pcWrap .fullBox .detailBox .item span:first-of-type {
  color: #fff;
  font-family: "DIN Alternate";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 100% */
  margin-bottom: 14px;
}
.pcWrap .fullBox .detailBox .item span:last-of-type {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.pcWrap .fullBox .detailBox .item:first-of-type {
  margin-right: 98px;
}
.pcWrap .fullBox .detailBox .item:nth-of-type(2) {
  margin-right: 112px;
}
.pcWrap .fullBox .detailBox .item:nth-of-type(3) {
  margin-right: 148px;
}
.pcWrap .fullBox .detailBox .item:nth-of-type(4) {
  margin-right: 80px;
}
.pcWrap .fullBox .detailBox .item:nth-of-type(5) {
  margin-right: 95px;
}
.pcWrap .bgBox {
  position: relative;
  min-width: 1200px;
  height: 384px;
  /* background: url("../../assets/img/fund/img/bgBox_bg.png") no-repeat 50% /
    cover; */
  margin-bottom: -100px;
}

/* 严格的资产安全管理 */
.pcWrap .box1 {
  width: 1200px;
  margin: 0 auto;
}
.pcWrap .box1 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 126.316% */
  margin-bottom: 27px;
}
.pcWrap .box1 .subTitle {
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.36px;
  margin-bottom: 53px;
}
.pcWrap .box1 .list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 180px;
}
.pcWrap .box1 .list .item {
  width: 590px;
  height: 130px;
  display: flex;
  align-items: center;
  padding-top: 12px;
  box-sizing: border-box;
  background: #161a27;
}
.pcWrap .box1 .list .item img {
  width: 112px;
  height: 112px;
  margin-right: 13px;
}
.pcWrap .box1 .list .item span {
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.pcWrap .box1 .list .item:last-of-type img {
  margin-left: 24px;
  margin-right: 23px;
}
/* 设立1亿美元风险保护基金 */
.pcWrap .box2 {
  margin-bottom: 200px;
  width: 1150px;
  margin: 0 auto 180px;
}
.pcWrap .box2 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 18px;
}
.pcWrap .box2 .title b {
  color: #ffa101;
  font-weight: 400;
}
.pcWrap .box2 .subTitle {
  margin-bottom: 70px;
  color: var(--homeLightGary);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}
.pcWrap .box2 .list {
  width: 1200px;
  margin: 0 auto 88px;
  display: flex;
  justify-content: space-between;
}
.pcWrap .box2 .list .item {
  position: relative;
  width: 580px;
  /* height: 192px; */
}
.pcWrap .box2 .list .item .innerBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 38px;
  padding-bottom: 38px;
  background: #000;
  border-radius: 8px;
  border: 1px solid #fff;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pcWrap .box2 .list .item .innerBox .mainText {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 19px;
}
.pcWrap .box2 .list .item .innerBox .subText {
  width: 372px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.32px;
}
.pcWrap .box2 .list .item:last-of-type .innerBox .subText {
  width: 447px;
}
.pcWrap .box2 .list .item .outBox {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #fff;
}
.pcWrap .box2 .imgBox {
  position: relative;
  width: 100%;
  min-width: 1200px;
  height: 264px;
  background: url("../../assets/img/fund/img/imgBox_bg.png") no-repeat 50% /
    cover;
}
.pcWrap .box2 .imgBox .img {
  position: absolute;
  width: 823px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.pcWrap .box2 .imgBox .btnImg {
  position: absolute;
  left: 50%;
  top: 180px;
  transform: translateX(-50%);
  height: 102px;
  cursor: pointer;
}
/* 极端事件全额先行赔付 */
.pcWrap .box3 {
  width: 1150px;
  margin: 0 auto 180px;
}
.pcWrap .box3 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 120% */
  margin-bottom: 60px;
}
.pcWrap .box3 .subTitle {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.32px;
  margin-bottom: 14px;
}
.pcWrap .box3 .line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.25);
  margin-bottom: 20px;
}
.pcWrap .box3 .desc {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.pcWrap .box3 .desc span {
  margin-right: 50px;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.32px;
}
.pcWrap .box3 .desc img {
  flex: none;
  height: 133px;
}
/* 实力造就传奇，4E成冠军之选 */
.pcWrap .box4 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 42px;
}
.pcWrap .box4 .subTitle {
  color: var(--homeLightGary);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 57px;
}
.pcWrap .box4 .outMainBox {
  width: 100%;
  margin: 0 auto;
  height: 440px;
  background: url(../../assets/img/fund/img/mainBox_bg.png) repeat 50% / cover;
  box-sizing: border-box;
}

.pcWrap .box4 .mainBox {
  width: 100%;
  min-width: 1200px;
  max-width: 1920px;
  margin: 0 auto;
  height: 440px;
  background: url("../../assets/img/fund/img/box4_bg.png") no-repeat 50% / cover;
  box-sizing: border-box;
  display: flex;
}
.pcWrap .box4 .mainBox .innerBox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 69px;
  box-sizing: border-box;
  display: flex;
}
.pcWrap .box4 .innerBox .left {
  padding-left: 33px;
  display: flex;
  flex-direction: column;
  margin-right: 80px;
}
.pcWrap .box4 .mainBox .left .mainText {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 18px;
}
.pcWrap .box4 .innerBox .left .subText {
  width: 498px;
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.32px;
}
.pcWrap .box4 .innerBox .right {
  width: 491px;
}
.pcWrap .box4 .innerBox .right .item {
  display: flex;
  min-height: 98px;
}
.pcWrap .box4 .innerBox .right .item1,
.pcWrap .box4 .innerBox .right .item2 {
  margin-bottom: 8px;
}
.pcWrap .box4 .innerBox .right .item .mini {
  flex: 0 0 212px;
  margin-right: 9px;
  padding-top: 28px;
  padding-left: 30px;
  background: #0d1327;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.pcWrap .box4 .innerBox .right .item .mini span:first-of-type,
.pcWrap .box4 .innerBox .right .item .big span:first-of-type {
  color: #fff;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 5px;
}
.pcWrap .box4 .innerBox .right .item .mini span:last-of-type,
.pcWrap .box4 .innerBox .right .item .big span:last-of-type {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.pcWrap .box4 .innerBox .right .item .big {
  flex: 0 0 270px;
  padding-top: 28px;
  padding-left: 30px;
  background: #0d1327;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.pcWrap .box4 .innerBox .right .item3 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 98px;
  width: 100%;
  padding-top: 28px;
  padding-left: 30px;
  background: #0d1327;
  box-sizing: border-box;
}
.pcWrap .box4 .innerBox .right .item3 span:first-of-type {
  color: #fff;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 5px;
}
.pcWrap .box4 .innerBox .right .item3 span:last-of-type {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.pcWrap .box4 .innerBox .right .item3 img {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 75px;
}

/* 底部 */
.pcWrap .footer {
  padding-top: 127px;
}
.pcWrap .footer .footer-top {
  width: 1060px;
  margin: 0 auto 155px;
  display: flex;
  justify-content: space-between;
}
.pcWrap .footer .footer-top .left {
  margin-right: 45px;
  margin-top: 12px;
}
.pcWrap .footer .footer-top .left .bigImg {
  width: 500px;
  height: 280px;
}
.pcWrap .footer .footer-top .left .smallImg {
  position: relative;
  top: 1px;
  width: 46px;
}
.pcWrap .footer .footer-top .right {
  padding-top: 10px;
  width: 464px;
}
.pcWrap .footer .footer-top .right .mainText {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 22px;
}
.pcWrap .footer .footer-top .right .subText {
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.32px;
}
.pcWrap .footer .footer-bottom {
  position: relative;
  width: 100%;
  min-width: 1200px;
  /* height: 355px; */
  background: url("../../assets/img/fund/img/footer_bg.png") no-repeat
    center/cover;
  padding-bottom: 75px;
}
.pcWrap .footer .footer-bottom .mainTitle {
  padding-top: 75px;
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 68px;
}
.pcWrap .footer .footer-bottom .text {
  width: 966px;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.36px;
  text-align: left;
  margin: 0 auto;
}
/* H5 */
.mWrap {
  /* background-color: #E3F1FF; */
  background: #000;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
}

.m_topBox {
  width: 100%;
}
.m_topBox .m_topInfo {
  position: relative;
  font-size: 0;
}
.m_topBox .m_topInfo .bg {
  width: 100%;
}
.m_topBox .m_topInfo .title1 {
  position: absolute;
  top: 73px;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px; /* 156.667% */
  letter-spacing: 0.6px;
}
.m_topBox .m_topInfo .title1 b {
  color: #ffa101;
}
.m_topBox .m_topInfo .title2 {
  position: absolute;
  top: 120px;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px; /* 156.667% */
  letter-spacing: 0.6px;
}
.m_topBox .m_topInfo .title3 {
  position: absolute;
  top: 167px;
  width: 100%;
  color: #aeb4d2;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 250% */
  letter-spacing: 0.48px;
  text-align: center;
}
.m_topBox .m_topInfo .title4 {
  position: absolute;
  top: 227px;
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.65);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.m_topBox .m_topInfo .detailBox {
  position: absolute;
  width: calc(100% - 40px);
  height: 200px;
  bottom: -165px;
  left: 20px;
  padding: 31px 12px 0 24px;
  background-color: #161a27;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.m_topBox .m_topInfo .detailBox .item {
  flex: 0 0 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 27px;
}
.m_topBox .m_topInfo .detailBox .item .mainText {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 4px;
}
.m_topBox .m_topInfo .detailBox .item .subText {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.mWrap .bgBox {
  width: 100%;
  height: 173px;
  background: url("../../assets/img/fund/img/m_bgBox_bg.png") no-repeat
    center/cover;
  font-size: 0;
}

/* 严格的资产安全管理 */
.mWrap .box1 {
  margin: 70px 0 96px;
}
.mWrap .box1 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 25px;
}
.mWrap .box1 .subTitle {
  width: 335px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
  margin-bottom: 25px;
}
.mWrap .box1 .list {
  width: calc(100% - 20px);
  height: 190px;
  margin: 0 auto;
  display: flex;
}
.mWrap .box1 .list .item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 12px 0;
  background: #161a27;
}
.mWrap .box1 .list .item:first-of-type {
  margin-right: 16px;
}
.mWrap .box1 .list .item img {
  width: 100px;
  margin-bottom: 6px;
}
.mWrap .box1 .list .item span {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* 设立1亿美元风险保护基金 */
.mWrap .box2 {
  margin-bottom: 92px;
}
.mWrap .box2 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}
.mWrap .box2 .subTitle {
  width: 250px;
  margin: 0 auto 27px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.mWrap .box2 .list {
  padding: 0 20px;
}
.mWrap .box2 .list .item {
  margin-bottom: 20px;
  padding: 25px 21px;
  border-radius: 8px;
  border: 1px solid #fff;
}
.mWrap .box2 .list .item .mainText {
  color: #fff;
  text-align: left;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 8px;
}
.mWrap .box2 .list .item .subText {
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.28px;
}
.mWrap .box2 .imgBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mWrap .box2 .imgBox .img {
  width: 100%;
}
.mWrap .box2 .imgBox .btnImg {
  width: 250px;
}

/* 极端事件全额先行赔付 */
.mWrap .box3 {
  margin: 70px 0 140px;
}
.mWrap .box3 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 27px;
}
.mWrap .box3 .innerBox {
  width: calc(100% - 40px);
  position: relative;
  padding: 28px 20px 87px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.65);
  border-radius: 16px;
}
.mWrap .box3 .innerBox .topText {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
}
.mWrap .box3 .innerBox .line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.25);
  margin: 22px 0;
}
.mWrap .box3 .innerBox .bottomText {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
}
.mWrap .box3 .innerBox .img {
  position: absolute;
  right: -12px;
  bottom: -25px;
  height: 100px;
}
/* 实力造就传奇 */
.mWrap .box4 {
  margin-bottom: 48px;
}
.mWrap .box4 .title {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 14px;
}
.mWrap .box4 .subTitle {
  margin: 55px 0 19px;
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}
.mWrap .box4 .text {
  width: 335px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0 auto;
}
.mWrap .box4 .list {
  margin-top: 23px;
  padding: 34px 20px;
  background: #2538fb;
}
.mWrap .box4 .list .item {
  width: 100%;
  height: 68px;
  display: flex;
  margin-bottom: 6px;
}
.mWrap .box4 .list .item .mini {
  width: 145px;
  height: 100%;
  padding: 12px 0 0 18px;
  margin-right: 7px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #0d1327;
}
.mWrap .box4 .list .item .big {
  width: 198px;
  height: 100%;
  padding: 12px 0 0 18px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #0d1327;
}
.mWrap .box4 .list .item .mainText {
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}
.mWrap .box4 .list .item .subText {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.mWrap .box4 .list .item3 {
  position: relative;
  width: 100%;
  height: 68px;
  padding: 12px 0 0 18px;
  display: flex;
  flex-direction: column;
  background: #0d1327;
}
.mWrap .box4 .list .item3 .mainText {
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}
.mWrap .box4 .list .item3 .subText {
  color: rgba(255, 255, 255, 0.65);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.mWrap .box4 .list .item3 img {
  position: absolute;
  right: 20px;
  bottom: -25px;
  width: 80px;
  height: 80px;
}

/* footer */
.mWrap .footer .footer-top .title {
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
  text-align: center;
}
.mWrap .footer .footer-top .text {
  width: 335px;
  margin: 0 auto 32px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  letter-spacing: 0.28px;
}
.mWrap .footer .footer-mid {
  padding: 0 20px;
  margin-bottom: 90px;
}
.mWrap .footer .footer-mid img {
  width: 100%;
}
.mWrap .footer .footer-btm {
  position: relative;
  width: 100%;
  padding-bottom: 100px;
}
.mWrap .footer .footer-btm img {
  width: 100%;
}
.mWrap .footer .footer-btm .mainText {
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.mWrap .footer .footer-btm .subText {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 335px;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.85);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.28px;
}
.pcWrap .box2 .hashBox {
  position: relative;
  width: 1160px;
  height: 310px;
  padding: 40px 26px 0;
  margin: 0 auto 30px;
  background: #091580;
  border-radius: 10px;
  box-sizing: border-box;
}

.pcWrap .box2 .hashBox .leftImg {
  position: absolute;
  left: -15px;
  top: -25px;
  height: 45px;
}

.pcWrap .box2 .hashBox .rightImg {
  position: absolute;
  top: -20px;
  right: -10px;
  height: 66px;
}

.pcWrap .box2 .hashBox .scrollBox {
  overflow-x: hidden;
}

.pcWrap .box2 .hashBox .hashList {
  height: 40px;
  margin-bottom: 18px;
  display: flex;
  overflow-x: auto;
}

.pcWrap .box2 .hashBox .hashList::-webkit-scrollbar {
  height: 1px;
}

.pcWrap .box2 .hashBox .hashList .item {
  /* width: 150px; */
  min-width: 150px;
  padding: 0 8px;
  border-radius: 4px;
  background: #1222aa;
  margin-right: 10px;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  /* 71.429% */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  flex: 0 0 auto;
}

.pcWrap .box2 .hashBox .hashList .item.active {
  background: #3045f0;
}

.pcWrap .box2 .hashBox .hashList .item:last-of-type {
  margin-right: 0;
}
.hashValue {
  border-radius: 8px;
  border: 0.5px solid #1c2ec5;
  background: #2034d5;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0.28px;
  padding: 7px;
  padding-bottom: 10px;
  padding-top: 2px;
  display: inline-block;
  margin-bottom: 24px;

  .hashCopy {
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    cursor: pointer;
  }
}
.pcWrap .box2 .hashBox .content {
  /* height: 155px; */
  padding: 32px 30px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(88deg, #3348f6 2.25%, #051599 95.15%);
  box-sizing: border-box;
}

.pcWrap .box2 .hashBox .content .text {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  /* 62.5% */
  letter-spacing: 0.32px;
  margin-bottom: 36px;
}

.pcWrap .box2 .hashBox .content .amount {
  color: #fff;
  font-family: "Open Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  /* 25% */
  letter-spacing: 0.8px;
}
.mWrap .box2 .hashBox {
  background: #091580;
  position: relative;
  width: 100%;
  height: 226px;
  padding: 20px 20px 0;
  margin: 0 auto 20px;
  box-sizing: border-box;
}

.mWrap .box2 .hashBox .scrollBox {
  overflow-x: hidden;
}

.mWrap .box2 .hashBox .hashList {
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  overflow-x: auto;
}

.mWrap .box2 .hashBox .hashList .item {
  width: 130px;
  height: 40px;
  box-sizing: border-box;
  width: 150px;
  padding: 0 8px;
  border-radius: 4px;
  background: #1222aa;
  margin-right: 10px;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  /* 71.429% */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}

.mWrap .box2 .hashBox .hashList .item.active {
  background: #3045f0;
}

.mWrap .box2 .hashBox .content {
  width: 100%;
  height: 120px;
  padding: 25px 0 0 24px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(88deg, #3348f6 2.25%, #051599 95.15%);
  box-sizing: border-box;
}

.mWrap .box2 .hashBox .content .text {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
}

.mWrap .box2 .hashBox .content .amount {
  color: #fff;
  font-family: "Open Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.56px;
}
.pcWrap .box2 .btnImg {
  height: 102px;
  margin: 0 auto;
  cursor: pointer;
  display: block;
}
.pcWrap .box2 .imgBox .btnImg {
  position: absolute;
  left: 50%;
  top: 180px;
  transform: translateX(-50%);
  height: 102px;
  cursor: pointer;
}
.mWrap .box2 .btnImg {
  display: block;
  width: 190px;
  margin: 0 auto;
}
.box4-container .box4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .subTitle {
    max-width: 1200px;
  }
}
:root[theme="light"] {
  #app {
    background: #f1f4ff;
  }
  .hashValue {
    border: 0.5px solid rgba(255, 255, 255, 0.18);
    background: #2d5ff1;
  }
  .pcWrap .box3 .line {
    background: rgba(0, 0, 0, 0.25);
  }
  .pcWrap {
    background: #f1f4ff;
  }
  .pcWrap .fullBox {
    background: url("../../assets/img/fund/img/light_bg.png") no-repeat 50% /
      cover;
  }
  .pcWrap .topBox .topInfo .title1 {
    color: #000;
  }
  .pcWrap .topBox .topInfo .title2 {
    color: #000;
  }
  .pcWrap .topBox .topInfo .title3 {
    color: #4e5ba0;
  }
  .pcWrap .topBox .topInfo .title4 {
    color: #000;
  }
  .pcWrap .fullBox .detailBox {
    background: #fff;
  }
  .pcWrap .fullBox .detailBox .item span:first-of-type {
    color: #000;
  }
  .pcWrap .fullBox .detailBox .item span:last-of-type {
    color: rgba(0, 0, 0, 0.65);
  }
  .pcWrap .bgBox {
    /* background: url("../../assets/img/fund/img/light_bgBox_bg.png") no-repeat
      50% / cover; */
  }
  .pcWrap .box1 .title {
    color: #000;
  }

  .pcWrap .box1 .list .item {
    background-color: #fff;
  }
  .pcWrap .box1 .list .item span {
    color: #000;
  }
  .pcWrap .box2 .title {
    color: #000;
  }
  .pcWrap .box2 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .box2 .list .item .innerBox {
    background: #fff;
  }
  .pcWrap .box2 .list .item .innerBox .mainText {
    color: #000;
  }
  .pcWrap .box2 .list .item .innerBox .subText {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .box2 .list .item .outBox {
    background: #7985ee;
  }
  .pcWrap .box2 .hashBox {
    background: #dae2ff;
  }
  .pcWrap .box2 .hashBox .hashList .item {
    background: #fff;
    color: #386bff;
  }
  .pcWrap .box2 .hashBox .hashList .item.active {
    background: #386bff;
    color: #fff;
  }
  .pcWrap .box2 .hashBox .content {
    background: #386bff;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .pcWrap .box3 .title {
    color: #000;
  }
  .pcWrap .box3 .subTitle {
    color: #000;
  }
  .pcWrap .box3 .desc span {
    color: #000;
  }
  .pcWrap .box4 .title {
    color: #000;
  }
  .pcWrap .box4 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .box4 .innerBox .right .item .mini {
    background: #fff;
  }
  .pcWrap .box4 .innerBox .right .item .mini span:first-of-type,
  .pcWrap .box4 .innerBox .right .item .big span:first-of-type {
    color: #000;
  }
  .pcWrap .box4 .innerBox .right .item .mini span:last-of-type,
  .pcWrap .box4 .innerBox .right .item .big span:last-of-type {
    color: #000;
  }
  .pcWrap .box4 .innerBox .right .item .big {
    background: #fff;
  }
  .pcWrap .box4 .innerBox .right .item3 {
    background: #fff;
  }
  .pcWrap .box4 .innerBox .right .item3 span:first-of-type {
    color: #000;
  }
  .pcWrap .box4 .innerBox .right .item3 span:last-of-type {
    color: #000;
  }
  .pcWrap .footer .footer-top .right .mainText {
    color: #000;
  }
  .pcWrap .footer .footer-top .right .subText {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .footer .footer-bottom {
    background: url("../../assets/img/fund/img/light_footer_bg.png") no-repeat
      center/cover;
  }
  .pcWrap .footer .footer-bottom .mainTitle {
    color: #333;
  }
  .pcWrap .footer .footer-bottom .text {
    color: #000;
  }
  .mWrap {
    background: #f1f4ff;
  }
  .m_topBox .m_topInfo .title1 {
    color: #000;
  }
  .m_topBox .m_topInfo .title2 {
    color: #000;
  }
  .m_topBox .m_topInfo .title3 {
    color: #4e5ba0;
  }
  .m_topBox .m_topInfo .title4 {
    color: rgba(0, 0, 0, 0.85);
  }
  .m_topBox .m_topInfo .detailBox {
    background: #fff;
  }
  .m_topBox .m_topInfo .detailBox .item .mainText {
    color: #000;
  }
  .m_topBox .m_topInfo .detailBox .item .subText {
    color: rgba(0, 0, 0, 0.65);
  }
  .mWrap .bgBox {
    background: url("../../assets/img/fund/img/light_m_bgBox_bg.png") no-repeat
      center/cover;
  }
  .mWrap .box1 .title {
    color: #000;
  }
  .mWrap .box1 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .box1 .list .item {
    background: #fff;
  }
  .mWrap .box1 .list .item span {
    color: #000;
  }
  .mWrap .box2 .title {
    color: #000;
  }
  .mWrap .box2 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .box2 .list .item {
    background: #fff;
  }
  .mWrap .box2 .list .item .mainText {
    color: #000;
  }
  .mWrap .box2 .list .item .subText {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .box2 .hashBox {
    background: #dae2ff;
  }
  .mWrap .box2 .hashBox .hashList .item {
    background: #fff;
    color: #386bff;
  }
  .mWrap .box2 .hashBox .hashList .item.active {
    background: #386bff;
    color: #fff;
  }
  .mWrap .box2 .hashBox .content {
    background: #386bff;
  }
  .mWrap .box3 .title {
    color: #000;
  }
  .mWrap .box3 .innerBox {
    border: 1px solid rgba(0, 0, 0, 0.65);
  }
  .mWrap .box3 .innerBox .topText {
    background: #f1f4ff;
    color: #000;
  }
  .mWrap .box3 .innerBox .line {
    background: rgba(0, 0, 0, 0.25);
  }
  .mWrap .box3 .innerBox .bottomText {
    color: #000;
  }
  .mWrap .box4 .title {
    color: #000;
  }
  .mWrap .box4 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .box4 .text {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .box4 .list .item .mini {
    background: #fff;
  }
  .mWrap .box4 .list .item .big {
    background: #fff;
  }
  .mWrap .box4 .list .item .mainText {
    color: #000;
  }
  .mWrap .box4 .list .item .subText {
    color: rgba(0, 0, 0, 0.65);
  }
  .mWrap .box4 .list .item3 {
    background: #fff;
  }
  .mWrap .box4 .list .item3 .mainText {
    color: #000;
  }
  .mWrap .box4 .list .item3 .subText {
    color: rgba(0, 0, 0, 0.65);
  }
  .mWrap .footer .footer-top .title {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .footer .footer-top .text {
    color: rgba(0, 0, 0, 0.85);
  }
  .mWrap .footer .footer-btm .mainText {
    color: #000;
  }
  .mWrap .footer .footer-btm .subText {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .box1 .subTitle {
    color: rgba(255, 255, 255, 0.85);
    text-align: center;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 166.667% */
    letter-spacing: 0.36px;
    margin-bottom: 53px;
  }
  .pcWrap .box1 .subTitle {
    color: rgba(0, 0, 0, 0.85);
  }
  .pcWrap .box1 .list .item img {
    width: 112px;
    height: 112px;
    margin-right: 13px;
  }
}
</style>
