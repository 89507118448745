<template>
  <div>
    <div class="hot-panel__container" id="hot-information">
      <div class="hot-panel__container-content">
        <div class="hot-panel__container-content-title">
          {{ translateTitle("了解行业热点，抓住投资机会") }}
        </div>
        <div class="hot-panel__container-content-items">
          <div
            class="hot-panel__container-content-items-item"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="hot-panel__container-content-items-item-info">
              <div
                class="hot-panel__container-content-items-item-info-title"
                @click="gotoHotDetail(item.share_url)"
              >
                {{ translateTitle(item.title) }}
              </div>
              <div class="hot-panel__container-content-items-item-info-summary">
                {{ translateTitle(item.description) }}
              </div>
              <div class="hot-panel__container-content-items-item-info-time">
                {{ getPublishTime(item.published_at) }}
              </div>
            </div>
            <div
              class="hot-panel__container-content-items-item-thumbnail"
              :style="getBgStyle(item)"
            >
              <!-- <img :src="item.cover_img" alt="" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot-panel__container banner">
      <div class="hot-panel__container-win">
        <div class="hot-panel__container-win-title">
          {{ translateTitle("与4E合作共赢") }}
        </div>
        <div class="hot-panel__container-win-subTitle">
          {{
            translateTitle(
              "通过与4E合作，为客户提供优质投资选择的同时，与4E一道成长。加入4E合伙人计划，成为4E合作伙伴，让我们共同成长！"
            )
          }}
        </div>
        <a
          class="hot-panel__container-win-btn"
          href="/cooperation#icooperation-page"
        >
          <span> {{ translateTitle("了解4E合伙人计划") }}</span>
        </a>
      </div>
    </div>
    <div class="hot-panel__container">
      <div class="hot-panel__container-security">
        <div
          class="hot-panel__container-security-item"
          v-for="(item, index) in securitys"
          :key="index"
        >
          <div class="hot-panel__container-security-item-img">
            <img
              :src="securitysImgs[isDarkMode ? 'dark' : 'light'][index]"
              alt=""
            />
          </div>
          <div>
            <div class="hot-panel__container-security-item-title">
              {{ translateTitle(item.title) }}
            </div>
            <div class="hot-panel__container-security-item-summary">
              {{ translateTitle(item.summary) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";

import routeSplicing from "@/assets/js/routeSplicing";
import { getLanguage } from "@/i18n/index.js";
import { getHotNewInfo } from "@/model/common";
import moment from "moment";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      securitysImgs: {
        dark: [
          require("@/assets/img/home/security/dark-01.png"),
          require("@/assets/img/home/security/dark-02.png"),
          require("@/assets/img/home/security/dark-03.png"),
        ],
        light: [
          require("@/assets/img/home/security/light-01.png"),
          require("@/assets/img/home/security/light-02.png"),
          require("@/assets/img/home/security/light-03.png"),
        ],
      },
      securitys: [
        {
          title: "专业合规",
          summary: "持有美国专业牌照及优质合作伙伴，为全球用户提供投资服务",
        },
        {
          title: "银行级安全保障",
          summary:
            "采⽤SSL加密、多重签名技术等多重安全措施，确保⽤戶的安全和隐私保护",
        },
        {
          title: "快捷出入金",
          summary: "支持多种支付方式及加密货币，7*24小时交易响应",
        },
      ],
      items: [],
    };
  },
  async created() {
    this.init();
  },
  destroyed() {},
  beforeDestroy() {},
  watch: {
    language() {
      this.items = [];
      this.init();
    },
  },
  computed: {},
  methods: {
    routeSplicing,
    getBgStyle(item) {
      return `background-image: url(${item.cover_img});`;
    },
    getPublishTime(time) {
      return moment(time * 1000).format("MM-DD HH:mm");
    },
    async init() {
      const res = await getHotNewInfo({
        lang: getLanguage(),
      });
      if (res.status === 200) {
        this.items = res.data;
      }
    },
    gotoHotDetail(link) {
      window.open(link, "_blank");
    },
    gotoCoopPage() {
      this.$router.push({
        path: this.routeSplicing(this.language, "cooperation"),
      });
    },
  },
};
</script>
<style lang="scss">
#app:not(.zh_CN, .zh_HK) {
  .hot-panel__container-win {
    width: 800px;
  }
  .hot-panel__container-content {
    align-items: flex-start;
  }
  .hot-panel__container-content-title {
    /* width: 822px;
    text-align: left; */
  }
}
.hot-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--homeBgColor);
  color: var(--homeFontColor);
  &.banner {
    background-image: url(../../assets/img/home/banners/top-banner-win.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
  }
  &-security {
    width: 1200px;
    padding: 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      /* margin-bottom: 120px; */
      border-radius: 12px;
      border: var(--homeGrayBorder);
      flex: 1;
      padding: 24px;
      &:hover {
        background: var(--homeHoverBgColor);
      }
      &:not(:last-child) {
        margin-right: 59px;
      }
      &:last-child {
        margin-right: 0;
      }
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 125% */
        margin-bottom: 12px;
      }
      &-summary {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 130% */
        color: var(--homeLightGary);
        /* margin-bottom: 16px; */
      }
      &-img {
        width: 62px;
        height: 46px;
        margin-bottom: 24px;
        img {
          /* width: 100%;
          height: 100%; */
        }
      }
    }
  }
  &-win {
    width: 600px;
    padding: 0 0 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px; /* 125% */
      margin-bottom: 12px;
      color: #fff;
    }
    &-subTitle {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
      margin-bottom: 48px;
      color: #fff;
    }
    &-btn {
      display: flex;
      min-width: 200px;
      height: 52px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 26px;
      background: #3348f6;
      > span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px; /* 131.25% */
        color: #fff;
      }
    }
  }
  &-content {
    width: 1200px;
    padding: 100px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px; /* 120% */
      margin-bottom: 80px;
      width: 100%;
      text-align: center;
    }
    &-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        padding: 20px;
        padding-top: 68px;
        padding-bottom: 68px;
        &:hover {
          background: var(--homeHoverBgColor);
        }
        &:not(:first-child) {
        }
        &:not(:last-child) {
          /* margin-bottom: 68px; */
          /* border-bottom: 1px solid #f0f0f0; */
          border: var(--homeGrayBorder);
          border-left: none;
          border-right: none;
          border-top: none;
        }
        &-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-right: 120px;
          flex: 1;
          &-title {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 125% */
            margin-bottom: 24px;
            cursor: pointer;
          }
          &-summary {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 130% */
            margin-bottom: 16px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: var(--homeLightGary);
          }
          &-time {
            color: var(--homeLightGary);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &-thumbnail {
          width: 240px;
          /* height: 150px; */
          /* background: red; */
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
          /* img {
            width: 240px;
            max-width: inherit;
            height: 100%;
          } */
        }
      }
    }
  }
}
</style>