<template>
  <div class="page-footer">
    <div class="first-content-outer">
      <div class="first-content">
        <div class="page-footer-content">
          <div class="logo-content-outer">
            <ul class="logo-content">
              <li>
                <a href="/">
                  <img :src="logImg" alt="" />
                </a>
              </li>
              <li class="sub-title slogan">
                <span class="web-slogan">
                  {{ slogan["web_slogan_" + language] }}
                </span>
              </li>
            </ul>
            <div class="language-title">
              {{ translateTitle("选择语言及地区") }}
            </div>
            <div class="language-current" @click="onMaskLangDialog">
              <div>
                <span>
                  <!-- {{ languageName }} -->
                  {{ language_name }}
                  <!-- 中文简体 -->
                </span>
                <span>/</span>
                <span>
                  {{ currencys.toUpperCase() }}
                  <!-- CNY -->
                </span>
              </div>
              <img src="../../assets/img_n/expand.png" class="expand" />
            </div>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("关于") }}</li>
              <a :href="tipUrl" target="_blank">
                <li>{{ translateTitle("公告中心") }}</li>
              </a>
              <a :href="helpUrl" target="_blank">
                <li>{{ translateTitle("帮助中心") }}</li>
              </a>
              <a href="/brand" target="_blank">
                <li>{{ translateTitle("关于我们") }}</li>
              </a>
            </ul>
          </div>
          <!-- <div>
        <ul class="text-content">
          <li class="sub-title">商务</li>
          <li>API接入</li>
          <li>上币申请</li>
        </ul>
      </div> -->
          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("条款") }}</li>
              <a :href="serviceUrl" target="_blank">
                <li>{{ translateTitle("服务协议") }}</li>
              </a>
              <a :href="privacyUrl" target="_blank">
                <li>{{ translateTitle("隐私协议") }}</li>
              </a>
              <a :href="statementUrl" target="_blank">
                <li>{{ translateTitle("免责声明") }}</li>
              </a>
            </ul>
          </div>
          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("其他") }}</li>
              <a :href="rateUrl" target="_blank">
                <li>{{ translateTitle("费率标准") }}</li>
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfoNEVg_bSZlWXYFnEdQRODQBJPSBfVKVL8IdXfcynufaKOuw/viewform?usp=sharing"
                target="_blank"
              >
                <li>{{ translateTitle("上币申请") }}</li>
              </a>
              <!-- <router-link
                :to="{
                  path: '/' + 'help-center' + '/' + language,
                  query: {
                    iframeSrc: 'https://help-center.justonlytest.com/#/',
                  },
                }"
                >{{ translateTitle("测试链接") }}</router-link
              > -->
              <a :href="apiUrl" target="_blank" v-if="hasAPI">
                <li>{{ translateTitle("API") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="tg-content">
              <li class="footer-icons">
                <a
                  v-for="(item, index) in contactUs"
                  :key="index"
                  :href="item.url"
                  target="_blank"
                >
                  <img :src="item.src" alt="" class="footer-icon" />
                  <!-- <i class="iconfont" :class="item.class"></i> -->
                </a>
              </li>
              <qrcode-vue
                class="qr_code_canvas"
                v-show="tgUrl"
                :value="tgUrl"
                :size="78"
                level="H"
              ></qrcode-vue>
              <li>{{ translateTitle(qrcodeTitle) }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- BJ页脚 -->
    </div>

    <el-dialog
      custom-class="langVisible_n"
      :visible.sync="langVisible"
      :show-close="false"
    >
      <img
        src="../../assets/img_n/close_icon.png"
        class="close_icon"
        @click="
          () => {
            langVisible = false;
          }
        "
      />
      <el-tabs v-model="activeName">
        <el-tab-pane :label="translateTitle('语言和地区')" name="first">
          <p class="pane-title">{{ translateTitle("选择语言和地区") }}</p>
          <ul>
            <li
              v-for="item in countryList"
              :key="item.value"
              @click="onLangClick(item)"
            >
              <!-- <img :src="item.img" alt="" /> -->
              <span :class="languageName == item.name ? 'red-lang-curr' : ''">{{
                item.name
              }}</span>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane :label="translateTitle('汇率')" name="second">
          <p class="pane-title">{{ translateTitle("选择汇率") }}</p>
          <ul>
            <li
              v-for="(item, index) in currencyMaps"
              :key="index"
              @click="onCurrencyChange(item.logic_name)"
            >
              <span :class="currency == item.abbreviate ? 'red-lang-curr' : ''">
                <!-- <img :src="item.icon" /> -->
                {{ `${item.mark} ${item.abbreviate}` }}
              </span>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { translateTitle } from "@/assets/js/i18n";
import { getLangPackage } from "@/assets/js/lang";
import QrcodeVue from "qrcode.vue";
import { getEnv } from "@/assets/js/stationSetting";
import { apiGetStaticTableInfo } from "@/model/settings";
import { getLanguage } from "@/i18n/index.js";
export default {
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      currencys: "user/currency",
    }),
    language_name() {
      let name = "";
      this.countryList.forEach((item) => {
        if (item.value == this.language) {
          name = item.name;
        }
      });
      return name;
    },
  },
  data() {
    return {
      slogan: "全球领先的金融资产交易平台",
      tgUrl: "",
      tipUrl: "",
      qrcodeTitle: "",
      rateUrl: "",
      serviceUrl: "",
      privacyUrl: "",
      statementUrl: "",
      helpUrl: "",
      projectName: "",
      logImg: "",
      apiUrl: "",
      contactUs: [],
      partnerUrl:
        "https://bitda.zendesk.com/hc/en-001/categories/4907990999055-Partner",
      hasAPI: false,
      langVisible: false,
      currencyMaps: {},
      activeName: "first",
      languageName: "中文简体",
      currency: "CNY",
      countryList: [
        {
          name: "English",
          value: "en",
          // img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          // img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          // img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          // img: fr,
        },
        {
          name: "Español",
          value: "es",
          // img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          // img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          // img: de,
        },
        {
          name: "Português",
          value: "pt",
          // img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          // img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          // img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          // img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          // img: th,
        },
        {
          name: "Türk",
          value: "tr",
          // img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          // img: it,
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          // img: id,
        },
        {
          name: "عربي",
          value: "ar",
          // img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          // img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          // img: zhHK,
        },
      ],
    };
  },
  created() {
    this.getStaticChainInfo();
    this.logImg = require("../../assets/img/logo_8coin.png");
    this.slogan = JSON.parse(sessionStorage.getItem("solgan"));
    if (!JSON.parse(sessionStorage.getItem("clause"))) {
      getEnv();
    }
    let clauseList = JSON.parse(sessionStorage.getItem("clause"));
    let othersList = JSON.parse(sessionStorage.getItem("others"));
    let noticeList = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    if (window.location.hostname.includes("4e.inc")) {
      this.contactUs = [
        {
          class: "",
          src: "https://app.eestatics.com/upload/2024/0423/EqFILN1713842863792.png",
          url: "https://www.facebook.com/4evietnam/",
          title: "facebook",
        },
        {
          class: "",
          src: "https://app.eestatics.com/2022/1011/UqFf6j1665470975733.png",
          url: "https://twitter.com/4evietnam",
          title: "Twitter",
        },
        {
          class: "",
          src: "https://app.eestatics.com/2022/1011/xtZCVL1665470975106.png",
          url: "https://www.instagram.com/4evietnam/",
          title: "Instagram",
        },
        {
          class: "",
          src: "https://app.eestatics.com/upload/2024/0423/kouZCJ1713842863918.png",
          url: "https://www.tiktok.com/@4e_vietnam",
          title: "Tiktok",
        },
        {
          class: "",
          src: "https://app.eestatics.com/2022/1011/2xxhvH1665470975119.png",
          url: "https://t.me/kenh4evietnam",
          title: "Telegram",
        },
        {
          class: "",
          src: "https://app.eestatics.com/upload/2024/0423/CNQ8e81713842863921.png",
          url: "https://www.youtube.com/channel/UC6Gz8WvZEvLH__06pWsowWw",
          title: "youtube",
        },
      ];

      this.tgUrl = "https://t.me/foure_vietnam";
    } else {
      this.contactUs = JSON.parse(sessionStorage.getItem("contact_us"));
      this.tgUrl = qrcode.ori_url;
    }

    if (othersList[0].language) {
      this.rateUrl = othersList[0].language[`url_${this.language}`]
        ? othersList[0].language[`url_${this.language}`]
        : othersList[0].language[`url_en`];
    } else {
      this.rateUrl = othersList[0].url;
    }
    if (othersList[1]) {
      this.hasAPI = true;
      this.apiUrl = othersList[1].url;
    } else {
    }

    if (clauseList[0].language) {
      this.serviceUrl = clauseList[0].language[`url_${this.language}`]
        ? clauseList[0].language[`url_${this.language}`]
        : clauseList[0].language[`url_en`];
    } else {
      this.serviceUrl = clauseList[0].url;
    }
    if (about[0].language) {
      this.tipUrl = about[0].language[`url_${this.language}`]
        ? about[0].language[`url_${this.language}`]
        : about[0].language[`url_en`];
    } else {
      this.tipUrl = about[0].url;
    }
    if (clauseList[1].language) {
      this.privacyUrl = clauseList[1].language[`url_${this.language}`]
        ? clauseList[1].language[`url_${this.language}`]
        : clauseList[1].language[`url_en`];
    } else {
      this.privacyUrl = clauseList[1].url;
    }

    if (clauseList[2].language) {
      this.statementUrl = clauseList[2].language[`url_${this.language}`]
        ? clauseList[2].language[`url_${this.language}`]
        : clauseList[2].language[`url_en`];
    } else {
      this.statementUrl = clauseList[2].url;
    }
    if (about[1].language) {
      this.helpUrl = about[1].language[`url_${this.language}`]
        ? about[1].language[`url_${this.language}`]
        : about[1].language[`url_en`];
    } else {
      this.helpUrl = about[1].url;
    }

    this.qrcodeTitle = qrcode.title;

    if (
      this.language == "zh-hk" ||
      this.language == "zh-tw" ||
      this.language == "zh_HK" ||
      this.language == "zh-TW"
    ) {
      this.partnerUrl =
        "https://bitda.zendesk.com/hc/zh-tw/categories/4907147995279-%E5%90%88%E4%BD%9C%E5%A4%A5%E4%BC%B4";
    } else if (this.language == "zh_CN") {
      // 假如浏览器语言是中文
      this.partnerUrl =
        "https://bitda.zendesk.com/hc/zh-cn/sections/4905697484431-%E6%88%90%E4%B8%BA-Bitda-%E7%9A%84%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4";
    } else {
      // 假如浏览器语言是英文
      this.partnerUrl =
        "https://bitda.zendesk.com/hc/en-001/categories/4907990999055-Partner";
    }

    let currency =
      localStorage.getItem("currency") ||
      (window.location.hostname.includes("4e.inc") ? "vnd" : "cny");
    // let currency =
    //   localStorage.getItem("currency") ||
    //   (window.location.hostname.includes("localhost") ? "vnd" : "cny");
    let tempLang = localStorage.getItem("language") || getLanguage();
    if (tempLang) {
      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    } else {
      tempLang = "zh_CN";

      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    }
    this.handleSelect(currency);
  },

  methods: {
    translateTitle,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
    }),
    async getStaticChainInfo() {
      await apiGetStaticTableInfo(7).then(({ data }) => {
        this.currencyMaps = data;
      });
    },
    onMaskLangDialog() {
      this.langVisible = true;
    },
    onLangClick(item) {
      // this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
      const langPackage = getLangPackage([item.value]);
      this.languageName = item.name;
      this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
      this.changeLanguage(item.value);
      this.$i18n.locale = item.value;
      this.langVisible = false;

      const url = window.location.href;
      const lastSlashIndex = url.lastIndexOf("/");
      const result = url.substring(0, lastSlashIndex);
      // window.location.href = `${result}/${this.language}`;
      const queryParams = window.location.href.split("?")[1];
      if (queryParams) {
        window.location.href = `${result}/${this.language}?${queryParams}`;
      } else {
        window.location.href = `${result}/${this.language}`;
      }
      // window.location.href = `${window.location.protocol}//${window.location.host}/${this.language}`;
    },
    handleSelect(key) {
      if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
      this.langVisible = false;
    },
    onKycClick() {
      this.$router.push({
        path: "/kyc-notice",
      });
    },
    onAPIClick() {
      this.$emit("onApiClick");
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
  },
  watch: {
    language: function (newValue, oldValue) {},
  },
};
</script>
<style lang="scss">
.page-footer {
  padding: 120px 0;
  display: flex;
  justify-content: center;
  .first-content-outer {
    width: 1200px;
    display: flex;
    justify-content: flex-start;
  }
  .first-content {
    display: flex;
    justify-content: center;
  }
  .second-content {
    width: 1000px;
    margin: 0px auto;
    padding: 20px 90px;
    border-top: 1px solid #fcd535;
    color: #fcd535;
    &.V2 {
      border-top: 1px solid #7e8392;
      color: #7e8392;
    }
    &.V3 {
      border-top: none;
      margin-top: 20px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        color: #786b8e;
      }
    }
    p {
      font-size: 14px;
      line-height: 2;
      margin-bottom: 10px;
      vertical-align: middle;
      img {
        width: 14px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }
  // background-color: #17232c;
  background-color: #000;
  color: #fff;
  text-align: center;
  user-select: none;

  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  .page-footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    // padding: 0px 360px;
    width: 1200px;
    .logo-content img {
      height: 42px;
    }

    .footer-icons {
      // margin-top: 30px;
      /* margin-top: 21px; */
      .iconfont {
        // width: 16px;
        // height: 12px;
        font-size: 18px;
        // color: #7e8392;
        color: #959da4;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
          color: #357de1;
          // width: 20px;
          // height: 20px;
          // border-radius: 50%;
          // color: #ffffff;
        }
      }
      .footer-icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .logo-content-outer {
      display: flex;
      flex-direction: column;
      .language-title {
        color: #8b909b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        margin-top: 24px;
        margin-bottom: 8px;
      }
      .language-current {
        cursor: pointer;
        width: 200px;
        height: 40px;
        /* margin-top: 32px; */
        padding: 10px;
        border-radius: 2px;
        border: solid 1px rgba(148, 158, 164, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        // font-weight: 500;
        color: #fff;
        .expand {
          width: 16px;
          height: 16px;
        }
      }
    }
    .logo-content {
      text-align: left;
      img {
        // width: 182px;
        // height: 42px;
        width: 199px;
        height: 44px;
      }
      .sub-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #8b909b !important;
        // &.slogan {
        //   width: 160px;
        // }
        .bitda-tg {
          width: 32px;
          height: 27px;
          margin-left: 13px;
          vertical-align: middle;
        }
      }
    }
    .tg-content {
      .qr_code_canvas {
        margin-bottom: 10px;
        canvas {
          border: 3px solid white;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      li {
        color: #8b909b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }
    .text-content {
      vertical-align: top;
      li {
        // color: #ffffff;
        color: #959da4;
        font-size: 12px;
        margin: 0 0 17px;
        text-align: left;
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      a {
        color: #959da4;
        font-size: 12px;
        margin: 0 0 17px;
        text-align: left;
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .sub-title {
        // font-size: 16px;
        // color: #7e8392;
        font-size: 16px;
        color: #fff;
        text-align: left;
        &:hover {
          color: #7e8392;
        }
      }
    }
  }
  .langVisible_n {
    z-index: 999;
    width: 840px;

    .close_icon {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 28px;
      right: 30px;
      z-index: 10;
      cursor: pointer;
    }
    .el-dialog__header {
      height: 0px;
      padding: 0 !important;
    }
    .el-dialog__body {
      padding-top: 16px;
      .el-tabs__header {
        margin-bottom: 22px;
      }
      .el-tabs__active-bar {
        background-color: #c61b3d;
      }
      .el-tabs__item {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 12px;
      }
      .pane-title {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    ul {
      // min-width: 700px;
      margin-left: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    li {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      width: 25%;
      &:hover {
        color: black;
      }
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #202630;
      }
      .red-lang-curr {
        color: #c61b3d;
      }
    }
  }
}
</style>
