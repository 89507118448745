<template>
  <div class="idol-page">
    <div class="idol-container" id="idol-page">
      <div class="idol_header">
        <div class="idol_header-content">
          <div class="idol_header-content-title">
            {{ translateTitle("赢在未来：4E × AFA") }}
          </div>
          <div class="idol_header-content-subTitle">
            {{ translateTitle("恭喜4E交易所成为阿根廷国家足球队全球赞助商") }}
          </div>
          <!-- <div class="idol_header-content-tips">
            {{
              translateTitle(
                "4E 交易所荣幸地宣布成为阿根廷足球队的全球赞助商。自 4E 交易所成立以来，我们一直致力于在金融资产交易行业推动创新。今天，我们很高兴地宣布，我们与阿根廷足球队建立了全球合作伙伴关系，这一合作基于彼此共同的雄心与价值观。"
              )
            }}
          </div> -->
        </div>
      </div>
      <div class="idol-content">
        <div class="idol-content_desc">
          <div class="idol-content_desc-title">
            {{ translateTitle("阿根廷国家足球队：卓越传奇") }}
          </div>
          <div class="idol-content_desc-info">
            {{
              translateTitle(
                "阿根廷足球队，被称为La Albiceleste，在全球舞台上拥有丰富的历史和卓越的传统。有着像 Lionel Messi、Paulo Dybala、Ángel Di María 和 Lautaro Martinez 这样的标志性球员，阿根廷以其球员的技艺、热情和决心赢得了全球足球迷的心。"
              )
            }}
          </div>
        </div>
      </div>
      <div class="idol-panel-title">
        {{ translateTitle("星光璀璨") }}
        <img class="idol-panel-title-bg" :src="lineIcon" alt="" />
        <div class="idol-items" style="margin-bottom: 0px">
          <div class="idol-item" v-for="item in idolList" :key="item.id">
            <div class="idol-item_icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="idol-item_info" v-if="isBigScreen">
              <div class="idol-item_info-enName">
                {{ translateTitle(item.enName) }}
              </div>
              <div class="idol-item_info-name">
                {{ translateTitle(item.name) }}
              </div>

              <div
                v-for="(info, index) in item.info"
                :key="index"
                class="idol-item_info-info"
              >
                {{ translateTitle(info) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="idol-panel-title" v-if="!isBigScreen">
      <div class="idol-items">
        <div class="idol-item" v-for="item in idolList" :key="item.id">
          <div class="idol-item_info">
            <div class="idol-item_info-enName">
              {{ translateTitle(item.enName) }}
            </div>
            <div class="idol-item_info-name">
              {{ translateTitle(item.name) }}
            </div>

            <div
              v-for="(info, index) in item.info"
              :key="index"
              class="idol-item_info-info"
            >
              {{ translateTitle(info) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="idol-panel-title"
      :style="{ marginTop: isBigScreen ? '100px' : '0px' }"
    >
      {{ translateTitle("愿景同享，携手并进") }}
      <img class="idol-panel-title-bg" :src="lineIcon" alt="" />
    </div>

    <div class="idol-footer-mask">
      <div class="idol-features">
        <div class="idol-features-item" v-for="item in features" :key="item.id">
          <div class="idol-features-item-icon">
            <img :src="isDarkMode ? item.icon : item.dIcon" alt="" />
          </div>
          <div class="idol-features-item_info">
            <div
              class="idol-features-item_info-title"
              :data-elWidth="translateTitle(item.title)"
            >
              <div class="idol-features-item_info-title-value">
                {{ translateTitle(item.title) }}
              </div>
            </div>
            <div class="idol-features-item_info-desc">
              {{ translateTitle(item.desc) }}
            </div>
          </div>
        </div>
      </div>
      <div class="idol-panel-title">
        <div>
          #{{ translateTitle("与")
          }}<span v-if="language !== 'zh_CN'" class="hight-title-content"
            >AFA</span
          >
          <span v-else class="hight-title-content-zh">AFA</span
          >{{ translateTitle("一起赢在未来").trim() }}
        </div>
        <img class="idol-panel-title-bg" :src="lineIcon" alt="" />
      </div>
      <div class="win-feature">
        <div class="win-feature-item">
          {{ translateTitle("在 4E Exchange，我们通过创新和合作塑造未来。") }}
        </div>
        <div class="win-feature-item">
          {{
            translateTitle(
              "我们的 #赢在未来 活动体现了这一理念，激励个人和组织拥抱变革，抓住机遇，在各自的领域追求卓越。通过与阿根廷足球队的合作，我们旨在鼓励全球粉丝加入我们，共同迈向增长和成功的旅程。"
            )
          }}
        </div>
        <div class="win-feature-item">
          {{
            translateTitle(
              "加入我们，与阿根廷足球队一起踏上这段激动人心的旅程。"
            )
          }}
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import commonMixin from "@/components/common/commonMixin.vue";
import icon1 from "@/assets/img/idol/lists/icon1.png";
import icon2 from "@/assets/img/idol/lists/icon2.png";
import icon3 from "@/assets/img/idol/lists/icon3.png";
import icon4 from "@/assets/img/idol/lists/icon4.png";
import f1Icon from "@/assets/img/idol/feature/f1.png";
import f2Icon from "@/assets/img/idol/feature/f2.png";
import f3Icon from "@/assets/img/idol/feature/f3.png";

import f1IconDark from "@/assets/img/idol/feature/f1-d.png";
import f2IconDark from "@/assets/img/idol/feature/f2-d.png";
import f3IconDark from "@/assets/img/idol/feature/f3-d.png";

import footerMask from "@/assets/img/idol/footer-mask.png";
import lineIcon from "@/assets/img/idol/line.png";
export default {
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      VUE_APP_ID,
      lineIcon,
      isBigScreen: false,
      idolList: [
        {
          id: 1,
          name: "莱昂内尔·梅西",
          enName: "Lionel Messi",
          icon: icon1,
          info: [
            "被普遍认为是有史以来最伟大的足球运动员之一，梅西的无与伦比的天赋和无与伦比的工作态度使他赢得了多项荣誉，包括多次金球奖。",
            "作为阿根廷队的队长，梅西凭借其出色的技术和领导力继续激励着一代又一代的足球迷。",
          ],
        },
        {
          id: 2,
          name: "保罗·戴巴拉",
          enName: "De Paul",
          icon: icon2,
          info: [
            "以他爆发力十足的速度和出色的盘带技术而闻名，德保尔是一名多才多艺的中场球员，以其出色表现征服了全球球迷。",
            "凭借他的创造力和技艺，德保尔为阿根廷的进攻实力增添了新的维度，使其成为国际赛场上备受关注的关键人物。",
          ],
        },
        {
          id: 3,
          name: "安赫尔·迪马利亚",
          enName: "Ángel Di María",
          icon: icon3,
          info: [
            "以其多才多艺和技术高超而闻名，迪玛利亚是一名经验丰富的中场球员，在阿根廷的多次成功中发挥了关键作用。",
            "凭借其创造得分机会的能力和精准的传球，迪玛利亚对阿根廷足球队是一个至关重要的资产。",
          ],
        },
        {
          id: 4,
          name: "劳塔罗·马丁内斯",
          enName: "Lautaro Martinez",
          icon: icon4,
          info: [
            "作为足球界的新星，马丁内斯以其速度、敏捷性和临门一脚而闻名。",
            "他在寻找机会和制造得分机会方面的能力使他在场上成为一名令人敬畏的存在，为阿根廷的进攻火力做出了贡献。",
          ],
        },
      ],
      features: [
        {
          id: 1,
          icon: f1Icon,
          dIcon: f1IconDark,
          title: "精准与技术",
          desc: "像在交易中一样，足球的成功也需要精确、准确和先进的技术。交易者需要根据市场数据做出准确的决策，就像足球运动员在场上依靠精准的传球和战术一样。两个行业都利用先进的技术来获得竞争优势——交易者使用复杂的算法和平台，而足球队则利用先进的训练设施和数据分析来优化性能。",
        },
        {
          id: 2,
          icon: f2Icon,
          dIcon: f2IconDark,
          title: "速度与敏捷性",
          desc: "速度和敏捷性在交易和足球中至关重要。交易者必须迅速应对市场变动，而足球运动员则需要在比赛中做出即时决策。此外，风险管理在两个领域中都起着至关重要的作用。交易者需要谨慎管理头寸以最小化损失，而足球队则需要在性能和可靠性之间取得平衡，以避免在场上发生昂贵的错误。",
        },
        {
          id: 3,
          icon: f3Icon,
          dIcon: f3IconDark,
          title: "决心、韧性与雄心",
          desc: "我们很高兴能与阿根廷足球队这样一支在足球界称霸的队伍进行合作，因为他们体现了我们决心、韧性和雄心的价值观。我们致力于成为各自领域的领导者，激励他人取得卓越成就，推动实现可能性的极限。",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    var viewportWidth = window.innerWidth;
    this.isBigScreen = viewportWidth > 2248;
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      console.log("resize");
      this.isBigScreen = window.innerWidth > 2248;
    },
  },
};
</script>

<style lang="scss">
#app:not(.zh_CN, .zh_HK) {
  .idol-container .idol_header-content-subTitle {
    width: 600px;
  }
}
@media screen and (min-width: 2248px) {
  .idol-container {
    /* background-size: 100% 100% !important; */
  }
}
:root[theme="light"] {
  --idol-width: 1240px;
  --idol-bgColor: linear-gradient(
    180deg,
    rgba(141, 155, 255, 0.2) 1.93%,
    rgba(141, 155, 255, 0) 88.46%
  );
  --idol-fontColor: #000;
  --idol-descBgColor: #fff;
  --idol-descShadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  .idol-container {
    background-image: url(../../assets/img/idol/banner-light.png);
    .idol_header-content-title {
      color: #465aff;
      background: transparent;
      -webkit-text-fill-color: #465aff;
    }
    .idol-features-item_info-title-value {
    }
  }
  .idol-features-item_info {
    border-radius: 12px;
    border: 1px solid #000;
    background: #fff;
    padding: 30px;
  }
  .idol-features-item_info-title::after {
    content: attr(data-elWidth);
    position: absolute;
    left: -3px;
    bottom: 0px;
    color: transparent;
    height: 8px;
    background: #465aff;
    opacity: 0.3;
    z-index: 0;
    padding-right: 6px;
    /* background: linear-gradient(94deg, blue, red); 标准语法 */
  }
  .idol-content_desc {
    border: 1px solid #000;
  }
  .win-feature {
    border: 1px solid #000;
  }
  .hight-title-content {
    color: #465aff;
    margin-left: 10px;
    margin-right: 10px;
  }
  .idol-footer-mask {
    /* background-image: url(/img/footer-mask.5b1415ab.png); */
    background-image: url(../../assets/img/idol/footer-mask-light.png);
  }
}
:root[theme="dark"] {
  --idol-width: 1240px;
  --idol-bgColor: #000;
  --idol-fontColor: #fff;
  --idol-descBgColor: #161a27;
  --idol-descShadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  .idol-container {
    background-image: url(../../assets/img/idol/banner-dark.png);
  }
}
.idol-page {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  background-color: var(--idol-bgColor);
  color: var(--idol-fontColor);
  * {
    user-select: none;
    word-break: break-word;
  }
}
.idol-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--idol-bgColor);
  color: var(--idol-fontColor);

  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 1920px 1178px; */
  /* background-size: 100% 100%; */
  background-position: center;
  .idol_header {
    display: flex;
    width: 100%;
    justify-content: center;

    &-content {
      height: 500px;
      width: var(--idol-width);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 30px;
      &-title {
        font-size: 40px;
        font-weight: bold;
        background: linear-gradient(94deg, #fff -22.96%, #9ddaff 121.87%);
        /* background: linear-gradient(94deg, blue, red); 标准语法 */
        -webkit-background-clip: text; /* Chrome, Safari */
        background-clip: text;
        -webkit-text-fill-color: transparent; /* Chrome, Safari */
        color: transparent; /* 在不支持 -webkit-text-fill-color 的浏览器中，字体颜色设置为透明 */
        display: inline-block;

        line-height: 56px;
      }
      &-subTitle {
        margin-top: 33px;
        color: var(--homeLightGary);
        /* PF_font/m/t_m24 */
        font-family: "PingFang SC";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 125% */
      }
      &-tips {
        margin-top: 16px;
        width: 600px;
        color: var(--idol-fontColor);
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
      }
    }
  }
  .idol-content {
    width: var(--idol-width);
    display: flex;
    flex-direction: column;
    margin-bottom: 160px;
    /* align-items: center; */

    &_desc {
      border-radius: 12px;
      background: var(--idol-descBgColor);
      box-shadow: var(--idol-descShadow);
      padding: 30px;
      &-title {
        /* PF_font/m/t_m24 */
        font-family: "PingFang SC";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 125% */
        color: var(--idol-fontColor);
        margin-bottom: 20px;
      }
      &-info {
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        color: var(--homeLightGary);
      }
    }
  }
}
.idol-items {
  width: var(--idol-width);
  display: flex;
  flex-direction: row;
  margin-bottom: 109px;
  .idol-item {
    /* border: 1px solid red; */
    &:not(:last-child) {
      margin-right: 16px;
    }
    &_icon {
      width: 298px;
      img {
        /* width: 298px;
        height: 281px; */
      }
    }
    &_info {
      color: var(--idol-fontColor);
      width: 298px;

      &-name {
        text-align: center;
        /* PF_font/m/t_m20 */
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 130% */
        margin-bottom: 28px;
      }
      &-enName {
        text-align: center;
        /* PF_font/m/t_m20 */
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 130% */
        margin-top: 32px;
        margin-bottom: 8px;
      }
      &-info {
        /* PF_font/r/t_r14_c */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
        text-align: left;
        color: var(--homeLightGary);
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.idol-panel-title {
  width: var(--idol-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--idol-fontColor);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 120% */

  &-bg {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 620px;
  }
  .hight-title-content-zh,
  .hight-title-content {
    color: #9ddaff;
  }
  .hight-title-content {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.idol-features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--idol-fontColor);
  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--idol-width);

    margin-bottom: 70px;

    /* &:not(:last-child) {
    } */
    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
    }
    &:nth-child(2n + 1) {
      .idol-features-item-icon {
        margin-left: 50px;
      }
    }
    &:nth-child(2n) {
      .idol-features-item-icon {
        margin-right: 50px;
      }
    }
    &-icon {
      img {
        width: 397px;
      }
    }
    &_info {
      padding-bottom: 30px;
      &-title {
        font-family: "PingFang SC";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        position: relative;
        display: inline-block;
        z-index: 1;
        display: flex;
        /* width: auto; */
        margin-bottom: 30px;
        padding-bottom: 30px;
        &::after {
          content: attr(data-elWidth);
          position: absolute;
          left: -3px;
          bottom: 0px;
          color: transparent;
          height: 8px;
          background: #465aff;
          z-index: 0;
          padding-right: 6px;
          /* background: linear-gradient(94deg, blue, red); 标准语法 */
        }
        &-value {
          position: absolute;
          line-height: 30px;
          margin-bottom: 30px;
          z-index: 12;
        }
      }
      &-desc {
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        margin-top: 24px;
        color: var(--homeLightGary);
      }
    }
  }
}
.idol-footer-mask {
  /* background-image: url(/img/footer-mask.png); */
  background-image: url(../../assets/img/idol/footer-mask.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.win-feature {
  border-radius: 12px;
  background: var(--idol-descBgColor);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
  color: var(--idol-fontColor);
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--idol-width);
  margin-bottom: 160px;

  &-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
