<template>
  <div>
    <div class="join-panel__container">
      <div class="join-panel__container-title">
        {{ translateTitle("立即加入 成为4E合伙人") }}
      </div>
      <el-steps :active="0" finish-status="success">
        <el-step title=""></el-step>
        <el-step title=""></el-step>
        <el-step title=""></el-step>
      </el-steps>
      <div class="join-panel__container-items">
        <div
          class="join-panel__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <!-- <img :src="item.icon" alt="" /> -->
          <div class="join-panel__container-items-item-title">
            {{ translateTitle(item.title) }}
          </div>
          <div class="join-panel__container-items-item-subtitle">
            {{ translateTitle(item.subTitle) }}
          </div>
        </div>
      </div>
      <span @click="onJoin" class="coop-top-panel__container-banner-btn">{{
        translateTitle("立即加入")
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      items: [
        {
          title: "申请成为合伙人",
          subTitle:
            "3分钟填写表单，将您的优势和资源与我们分享，4E团队将会第一时间审核后由专业人员与您联络。",
        },
        {
          title: "分享链接 开始推广",
          subTitle:
            "将邀请链接或邀请码分享给好友和粉丝，或在社交媒体和其他渠道中进行宣传和推广。",
        },
        {
          title: "赚取业内顶级返佣",
          subTitle:
            "受邀用户注册并开始交易，您即可获得交易手续费返佣，将您的影响力成功变现。",
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    onJoin() {
      window.open("https://forms.gle/xNSDwfY6h1WheGKUA", "_blank");
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .join-panel__container {
    .el-steps.el-steps--horizontal {
      width: 822px;
      margin-top: 80px;
      .el-step__head.is-process {
        .el-step__icon {
          background: #000;
        }
        .el-step__icon-inner {
          color: #fff;
        }
      }
      .el-step__line {
        background-color: var(--homeFontColor);
      }
      .el-step__icon {
        border: 1px solid var(--homeFontColor);
      }
      .el-step__icon-inner {
        color: var(--homeFontColor);
      }
    }
  }
}
:root[theme="dark"] {
  .join-panel__container {
    .el-steps.el-steps--horizontal {
      width: 822px;
      margin-top: 80px;
      .el-step__head.is-process {
        .el-step__icon {
          background: #fff;
        }
        .el-step__icon-inner {
          color: #000;
        }
      }
      .el-step__line {
        background-color: var(--homeFontColor);
      }
      .el-step__icon {
        border: 1px solid var(--homeFontColor);
        background: #000;
      }
      .el-step__icon-inner {
        color: var(--homeFontColor);
      }
    }
  }
}

.join-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  .coop-top-panel__container-banner-btn {
    background: var(--homeColorBtnBgColor);
    color: #fff;
  }
  .el-step__icon {
    width: 38px;
    height: 38px;
    padding: 4px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 180% */
  }
  .el-step.is-horizontal .el-step__line {
    top: 18px;
  }
  /* &.light-mode {
    background-color: var(--homeBgColor);
  } */
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      border: var(--homeGrayBorder);
      background: transparent;
      flex: 1;
      padding: 24px;
      &:hover {
        background: var(--homeHoverBgColor);
        border: 1px solid var(--Dark04-text-c_m, #465aff);
        background: linear-gradient(
          131deg,
          rgba(74, 202, 250, 0.08) 0%,
          rgba(236, 54, 235, 0.08) 100%
        );
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 32px;
      }
      > img {
        width: 120px;
        height: 120px;
      }
      &-title {
        color: var(--homeFontColor);
        /* PF_font/m/t_m28 */
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 128.571% */
        width: 100%;
      }
      &-subtitle {
        color: var(--homeLightGary);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 130% */
        margin-top: 16px;
      }
    }
  }
}
</style>