<template>
  <div
    class="trade-data__container"
    :style="{ backgroundImage: `url(${isDarkMode ? worldDark : worldLight})` }"
  >
    <div class="trade-data__container-title">
      {{ translateTitle("要交易 用4E") }}
    </div>
    <div class="trade-data__container-tips">
      {{ translateTitle("一站式投资全球热门资产 投资无界限") }}
    </div>
    <div class="trade-data__container-items">
      <div
        class="trade-data__container-items-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="trade-data__container-items-item-title">
          {{ translateTitle(item.title) }}
          <span class="trade-data__container-items-item-title-suffix">
            {{ item.suffix }}
          </span>
        </div>
        <div class="trade-data__container-items-item-subtitle">
          {{ translateTitle(item.subTitle) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import worldDark from "@/assets/img/world.png";
import worldLight from "@/assets/img/world-light.jpg";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      worldDark,
      worldLight,
      items: [
        {
          title: "340",
          subTitle: "优质金融资产",
          suffix: "+",
        },
        {
          title: "100万",
          subTitle: "全球用户",
          suffix: "+",
        },
        {
          title: "20",
          subTitle: "国家分布",
          suffix: "+",
        },
        {
          title: "7*24H",
          subTitle: "服务支持",
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.trade-data__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 498px;
  background-color: var(--homeBgLightColor);
  /* background-image: var(
    --homeWroldImg
  ); //url(../../../assets/img/world-light.jpg); */
  color: var(--homeFontColor);
  &-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 18px;
  }
  &-tips {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 80px;
    color: var(--homeLightGary);
  }
  &-items {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 264px;
      height: 132px;
      border: var(--homeGrayBorder);
      border-radius: 12px;
      padding: 24px;
      /* margin-left: 20px; */
      margin-top: 20px;
      &:hover {
        background: var(--homeHoverBgColor);
      }
      &-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 46px;
        margin-bottom: 10px;
        &-suffix {
          font-weight: 500;
          line-height: 48px;
          font-size: 32px;
          position: relative;
          left: -12px;
        }
      }
      &-subtitle {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: var(--homeLightGary);
        text-align: center;
      }
    }
  }
}
</style>