import VueRouter from "vue-router";
import auth from "./auth.js";
import Exchange from "@/pages/exchange/index.vue";
import Markets from "@/pages/markets/markets.vue";
import Assets from "@/pages/ucenter/assets.vue";
import Situation from "@/pages/ucenter/situation.vue";
import Security from "@/pages/login/security.vue";
// import Security from "@/pages/ucenter/security-setting.vue";
import Orderbook from "@/pages/exchange/orderbook.vue";
import ContractExchange from "@/pages/contract-exchange/contract-exchange.vue";
import ContractOrderbook from "@/pages/contract-exchange/contract-orderbook.vue";
import StockExchange from "@/pages/stock-exchange/stock-exchange.vue";
import ErrorPage from "@/pages/error-page/index";
import Home from "@/pages/home/home";
import Cooperation from "@/pages/cooperation/index";
import BrandPromotion from "@/pages/brand-promotion/index";
import Fund from '@/pages/fund/index'
import IdolPage from '@/pages/idol/index'
import KycNotice from "@/pages/notice/kyc-notice.vue";
import Finances from "@/pages/finances/index";
import MiningPool from "@/pages/mining-pool/index.vue";
import Delivery from "@/pages/currency-based-contract/currency-based-contract.vue";
import DeliveryOrderbook from "@/pages/currency-based-contract/currency-based-contract-orderbook.vue";
import HelpCenter from "@/pages/help-center/index.vue";
import MarketsNew from "@/pages/markets_new/markets_new.vue";
// import index from "@/pages/ucenter/index.vue"
// import Login from '@/pages/login/index'

// 用户中心相关路由
import ucenter from "../pages/ucenter/routers";
// import ucenter  from "../pages/ucenter/router/index"
const routes = [
  ucenter,
  {
    path: "/login/:lang?",
    name: "Login",
    meta: {
      title: "登录",
    },
    // component: () => import("@/pages/login/index"),
    component: () => import("@/pages/users/login.vue"),
  },
  {
    path: "/login-input-password/:lang?",
    name: "LoginInputPassword",
    meta: {
      title: "登录输入密码",
    },
    // component: () => import("@/pages/login/index"),
    component: () =>
      import("@/pages/users/components/login-input-password.vue"),
  },
  {
    path: "/safety-verify/:lang?",
    name: "SafetyVerify",
    meta: {
      title: "安全验证",
    },
    component: () => import("@/pages/users/components/safety-verify.vue"),
  },
  {
    path: "/safety-verify-code/:lang?/:token?",
    name: "SafetyVerifyCode",
    meta: {
      title: "安全验证码验证",
    },
    component: () => import("@/pages/users/components/safety-verify-code.vue"),
  },
  {
    path: "/backlogin/:lang?",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("@/pages/login/index"),
  },

  // {
  //   path: "/forgetPwd/:lang?",
  //   name: "ForgetPwd",
  //   meta: {
  //     title: "忘记密码",
  //   },
  //   // component: Login,
  //   component: () => import("@/pages/login/forgetPwd"),
  // },
  {
    path: "/forgetPwd/:lang?",
    name: "ForgetPwd",
    meta: {
      title: "忘记密码",
    },
    // component: Login,
    component: () => import("@/pages/users/forget-password.vue"),
  },
  {
    path: "/resetPassword/:lang?",
    name: "ResetPassword",
    meta: {
      title: "重置密码",
    },
    // component: Login,
    component: () => import("@/pages/users/components/reset-password.vue"),
  },
  {
    path: "/backregister/:lang?",
    name: "BackRegister",
    meta: {
      title: "注册",
    },
    // component: Login,
    component: () => import("@/pages/login/register"),
  },
  {
    path: "/register/:lang?",
    name: "Register",
    meta: {
      title: "注册",
    },
    // component: Login,
    component: () => import("@/pages/users/register"),
  },
  {
    path: "/register-verify/:lang?",
    name: "RegisterVerify",
    meta: {
      title: "注册验证",
    },
    // component: Login,
    component: () => import("@/pages/users/components/register-verify.vue"),
  },
  {
    path: "/register-set-password/:lang?",
    name: "RegisterSetPassword",
    meta: {
      title: "注册验证",
    },

    component: () => import("@/pages/users/components/set-password.vue"),
  },
  {
    path: "/login/mobile/:lang?",
    name: "LoginMobile",
    meta: {
      title: "登录",
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/email/:lang?",
    name: "LoginEmail",
    meta: {
      title: "邮箱登陆",
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/verify/:lang?",
    name: "LoginVerify",
    meta: {
      title: "绑定验证",
    },
    component: () => import("@/pages/login/loginFirstBind"),
  },
  //币币交易
  {
    path: "/exchange",
    meta: {
      title: "币币交易",
    },
    component: Exchange,
  },
  {
    path: "/exchange/:symbol/:lang?",
    meta: {
      title: "币币交易",
    },
    component: Exchange,
  },
  //合约交易
  {
    path: "/contract-exchange",
    meta: {
      title: "U本位合约",
    },
    component: ContractExchange,
  },
  {
    path: "/contract-exchange/:symbol/:lang?",
    meta: {
      title: "U本位合约",
    },
    component: ContractExchange,
  },
  //币本位合约交易
  {
    path: "/delivery",
    meta: {
      title: "币本位合约",
    },
    component: Delivery,
  },
  {
    path: "/delivery/:symbol/:lang?",
    meta: {
      title: "币本位合约",
    },
    component: Delivery,
  },
  {
    path: "/delivery-orderbook/:lang?",
    meta: {
      title: "币本位合约盘口",
    },
    component: DeliveryOrderbook,
  },
  //股票交易
  {
    path: "/stock-exchange/:lang?",
    meta: {
      title: "股票交易",
    },
    component: StockExchange,
  },
  {
    path: "/stock-exchange/:symbol/:lang?",
    meta: {
      title: "股票交易",
    },
    component: StockExchange,
  },
  {
    path: "/orderbook/:lang?",
    meta: {
      title: "币币盘口",
    },
    component: Orderbook,
  },
  {
    path: "/contract-orderbook/:lang?",
    meta: {
      title: "合约盘口",
    },
    component: ContractOrderbook,
  },

  //行情
  {
    path: "/markets/:lang?",
    meta: {
      title: "行情",
    },
    component: Markets,
  },
  {
    path: "/cooperation/:lang?",
    meta: {
      title: "合作推广",
    },
    component: Cooperation,
  },
  {
    path: "/brand/:lang?",
    meta: {
      title: "品牌推广",
    },
    component: BrandPromotion,
  },
  {
    path: "/fund/:lang?",
    meta: {
      title: "风险基金",
    },
    component: Fund,
  },
  {
    path: "/idol/:lang?",
    meta: {
      title: "代言人介绍",
    },
    component: IdolPage,
  },

  //行情新版
  {
    path: "/markets-new/:lang?",
    meta: {
      title: "行情",
    },
    component: MarketsNew,
  },
  //资产管理
  {
    path: "/ucenter/assets/:lang?",
    meta: {
      title: "资产管理",
    },
    component: Assets,
  },
  {
    path: "/fund/:lang?",
    meta: {
      title: "风险基金",
    },
    component: Fund,
  },
  {
    name: "Security",
    path: "/loginSecurity/:lang?",
    meta: {
      title: "安全项丢失",
    },
    component: Security,
  },
  {
    name: "Finances",
    path: "/finances/:lang?",
    meta: {
      title: "金融理财",
    },
    component: Finances,
  },

  {
    name: "KycNotice",
    path: "/kyc-notice/:lang?",
    meta: {
      title: "KYC-notice",
    },
    component: KycNotice,
  },

  {
    name: "MiningPool",
    path: "/mining-pool/:lang?",
    meta: {
      title: "MiningPool",
    },
    component: MiningPool,
  },
  {
    name: "HelpCenter",
    path: "/help-center/:lang?",
    meta: {
      title: "HelpCenter",
    },
    component: HelpCenter,
    // children: [
    //   {
    //     path: "*",
    //     meta: {
    //       title: "HelpCenter",
    //     },
    //     component: HelpCenter,
    //   },
    // ],
  },

  //停用404
  {
    redirect: "/404",
    path: "*",
    component: ErrorPage,
    meta: {
      title: "404",
    },
    children: [
      {
        path: "/404",
        meta: {
          title: "404",
        },
        component: ErrorPage,
      },
    ],
  },

  {
    path: "/:lang?",
    meta: {
      title: "首页",
    },
    component: Home,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
auth(router);

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
