<template>
  <div>
    <div class="about-panel__container">
      <div class="about-panel__container-content">
        <img
          class="about-panel__container-content-logo"
          :src="aboutLogo"
          alt=""
        />
        <div class="about-panel__container-content-infos">
          <div class="about-panel__container-content-infos-item">
            <div class="about-panel__container-content-infos-item-container">
              <div
                class="about-panel__container-content-infos-item-container-title"
              >
                {{ translateTitle("Empower 扶摇直上") }}
              </div>
              <div
                class="about-panel__container-content-infos-item-container-subTitle"
              >
                {{ translateTitle("4E致力赋能用户，掌握人生财富主权") }}
              </div>
            </div>
            <div
              class="about-panel__container-content-infos-item-container-title"
            >
              <div
                class="about-panel__container-content-infos-item-container-title"
              >
                {{ translateTitle("Evolve 与时俱进") }}
              </div>
              <div
                class="about-panel__container-content-infos-item-container-subTitle"
              >
                {{ translateTitle("4E紧握时代脉搏，引领全球交易新风向") }}
              </div>
            </div>
          </div>
          <div class="about-panel__container-content-infos-item">
            <div class="about-panel__container-content-infos-item-container">
              <div
                class="about-panel__container-content-infos-item-container-title"
              >
                {{ translateTitle("Enrich 锦上添花") }}
              </div>
              <div
                class="about-panel__container-content-infos-item-container-subTitle"
              >
                {{ translateTitle("4E专注用户体验，创造更多价值与满足") }}
              </div>
            </div>
            <div class="about-panel__container-content-infos-item-container">
              <div
                class="about-panel__container-content-infos-item-container-title"
              >
                {{ translateTitle("Exchange 携手共赢") }}
              </div>
              <div
                class="about-panel__container-content-infos-item-container-subTitle"
              >
                {{ translateTitle("4E促进交流合作，与用户共享经验资源") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aboutLogo from "@/assets/img/brand/about-logo.png";
import aboutLogoDark from "@/assets/img/brand/about-logo-dark.png";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
    }),
    aboutLogo() {
      return this.isDarkMode ? aboutLogoDark : aboutLogo;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.about-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  &-content {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 1200px;
    &-logo {
      display: flex;
      width: 180px;
      height: 206.225px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 29.801px;
    }
    &-infos {
      flex: 1;
      margin-left: 100px;
      display: flex;
      flex-direction: row;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &:first-child {
          margin-right: 100px;
        }
        &-container {
          &:first-child {
            margin-bottom: 60px;
          }
          &-title {
            color: var(--homeFontColor);
            /* PF_font/m/t_m24 */
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 125% */
          }
          &-subTitle {
            color: var(--homeLightGary);
            /* PF_font/r/t_r20 */
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 130% */
            margin-top: 24px;
          }
        }
      }
    }
  }
}
</style>