<template>
  <div>
    <brand-top-banner />
    <barnd-trade-data />
    <feature-panel />
    <ad-panel />
    <mission-panel :customStyle="missionCustomStyle" />
    <about-panel />
  </div>
</template>
<script>
import AdPanel from "@/components/home/ad-panel.vue";
import DownloadPanel from "@/components/home/download-panel.vue";
import FaqPanel from "@/components/home/faq-panel.vue";
import HonorPanel from "@/components/home/honor-panel.vue";
import HotPanel from "@/components/home/hot-panel.vue";
import RegisterPanel from "@/components/home/register-panel.vue";
import TopBanner from "@/components/home/top-banner.vue";
import TradeChart from "@/components/home/trade-chart.vue";
import TradeData from "@/components/home/trade-data.vue";
import BrandTopBanner from "@/components/home/brand-top-banner.vue";
import FeaturePanel from "@/components/home/feature-panel.vue";
import MissionPanel from "@/components/home/mission-panel.vue";
import AboutPanel from "@/components/home/about-panel.vue";
import { mapGetters, mapActions } from "vuex";
import Cookies from "js-cookie";
import BarndTradeData from "@/components/home/barnd-trade-data.vue";
export default {
  props: {},
  components: {
    AdPanel,
    BrandTopBanner,
    FeaturePanel,
    MissionPanel,
    AboutPanel,
    BarndTradeData,
  },
  mixins: [],
  data() {
    return {
      missionCustomStyle: {},
    };
  },
  async created() {
    this.missionCustomStyle = this.getMissionCustomStyle(false);
  },
  destroyed() {},
  beforeDestroy() {},
  watch: {
    isDarkMode() {
      this.missionCustomStyle = this.getMissionCustomStyle(false);
    },
  },
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
    }),
  },
  methods: {
    getMissionCustomStyle(isLight) {
      return {
        ...this.getBgColor(isLight),
        paddingTop: "50px",
      };
    },
    getBgColor(isLight) {
      const rootElement = document.documentElement;
      const computedStyle = getComputedStyle(rootElement);
      const homeBgLightColor =
        computedStyle.getPropertyValue("--homeBgLightColor");
      const homeBgColor = computedStyle.getPropertyValue("--homeBgColor");
      return {
        backgroundColor: isLight ? homeBgLightColor : homeBgColor,
      };
    },
  },
};
</script>
<style lang="scss">
</style>
