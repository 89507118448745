<template>
  <div class="faq-panel__container" :style="customStyle">
    <div class="faq-panel__container-content">
      <div class="faq-panel__container-content-title">
        {{ translateTitle("常见问题") }}
      </div>
      <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            {{ translateTitle("4E有哪些产品") }}
          </template>
          <div>
            {{
              translateTitle(
                "4E是全球领先的全品类的金融衍生品交易平台，旨在为客户提供安全、便捷的一站式交易体验。凭借丰富的行业经验和先进技术，为用户提供所有科技金融领域的全方面解决方案。"
              )
            }}
          </div>
          <div>
            {{
              translateTitle(
                "在4E，用户可以进行以下五大模块的交易：数字资产、外汇、商品和ETF、个股和股指"
              )
            }}
          </div>
        </el-collapse-item>
        <el-collapse-item
          :title="translateTitle(`4E App支持应用商店下载的国家及地区`)"
        >
          <div>
            {{
              translateTitle(
                "4E最新版本已上架Apple store&Google play，以下国家和地区的Apple store已开放下载："
              )
            }}
          </div>
          <div>
            {{
              translateTitle(
                `香港、越南、美国、菲律宾、泰国、柬埔寨、日本、格鲁吉亚、英国、加拿大、新加坡、泰国、韩国、印尼、尼日利亚、台湾、马来西亚、老挝、澳大利亚、埃及、西班牙、葡萄牙以及除委瑞内拉之外的整个拉美国家（共56个国家和地区）其他地区用户可以下载官方Beta版或Android APK安装使用4E App。`
              )
            }}
          </div>
        </el-collapse-item>
        <el-collapse-item :title="translateTitle('如何在4E购买资产和进行交易')">
          <div>
            {{
              translateTitle(
                `4E用户可选择充值加密货币或是使用4E的法币入金服务购买加密货币进行交易。若您需要使用法币相关的服务，请在使用前确保您完成4E的身份认证。`
              )
            }}
          </div>
        </el-collapse-item>
        <el-collapse-item :title="translateTitle('不提供服务的地区')">
          <div>
            {{
              translateTitle(
                `4E作为全球领先的的金融资产交易平台，在世界各地提供服务，支持多种的语言和法定货币。然而，由于国际金融法规的复杂性，4E的可用性在某些地区受到限制。其限制IP注册地区如下：`
              )
            }}
          </div>
          <div>
            {{
              translateTitle(
                `朝鲜、古巴、叙利亚、伊朗、委内瑞拉、苏丹、南苏丹、克里米亚、俄罗斯、黎巴嫩、伊拉克、利比亚、美国、孟加拉、印度、巴基斯坦`
              )
            }}
          </div>
          <div>
            {{
              translateTitle(
                `根据当地相关法令规范，4E无法对以上国家用户提供服务，敬请见谅。`
              )
            }}
          </div>
        </el-collapse-item>
        <el-collapse-item :title="translateTitle('平台费率标准')">
          <div>
            <span :style="'margin-right:6px;'">{{
              translateTitle(`您可以`)
            }}</span
            ><a
              class="faq-link"
              href="/help-center?path=category&code=PROBLEM%3AOther_Topics%3APlatforms&showContent=true&articleId=f4c16792-7b14-11ee-96c5-42010a35b029"
              target="_blank"
              >{{ translateTitle(`通过此链接`) }}</a
            >
            {{ translateTitle(`了解4E平台费率详情`) }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      activeNames: ["1"],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.faq-link {
  color: #465aff;
}
.faq-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--homeBgColor);
  color: var(--homeFontColor);
  &-content {
    width: 1200px;
    padding: 100px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      color: var(--homeFontColor);
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px; /* 120% */
      margin-bottom: 32px;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse {
      width: 100%;
      border: none;
      .el-collapse-item {
        border: var(--homeGrayBorder);
        padding: 32px;
        margin-bottom: 32px;
        border-radius: 12px;
        &:hover .el-icon-arrow-right:before {
          content: "\e7a0";
        }
        &:hover .el-icon-arrow-right.is-active:before {
          content: "\e7a2";
        }
      }
      .el-icon-arrow-right:before {
        content: "\e6d9";
      }
      .el-icon-arrow-right.is-active:before {
        content: "\e7a2";
      }
      .el-collapse-item__arrow.is-active {
        transform: rotate(180deg);
      }
      .el-collapse-item__header {
        overflow: hidden;
        color: var(--homeFontColor);
        text-overflow: ellipsis;
        /* PF_font/m/t_m28 */
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 128.571% */
        border-bottom: none;
        background-color: transparent;
      }
      .el-collapse-item__wrap {
        background-color: var(--homeBgColor);
        color: var(--homeFontColor);
        border-bottom: none;
        .el-collapse-item__content {
          font-size: 16px;
          color: var(--homeLightGary);
        }
      }
    }
  }
}
</style>