var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"user-center-menu el-menu-item nav-menu-item logins"},[_c('el-submenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.userInfoShow),expression:"userInfoShow"}],staticClass:"no-arrow",staticStyle:{"display":"none"},attrs:{"popper-class":"sub-item-wrap select-user-item","index":"3","collapse-transition":false,"popper-append-to-body":false,"show-timeout":0,"hide-timeout":0}},[_c('template',{slot:"title"},[_c('router-link',{staticClass:"uers-ucenter-n",class:_vm.getUserCenterClass,attrs:{"active-class":"none-active","to":_vm.routeSplicing(_vm.language, 'ucenter/situation')}},[_c('span',{on:{"click":function($event){_vm.goTo(_vm.routeSplicing(_vm.language, 'ucenter/situation'), 'personal')}}},[_c('img',{staticClass:"user-icon",attrs:{"src":require("../../assets/img_n/person.png"),"alt":""}})])])],1),_c('div',{staticClass:"user-msg"},[_c('p',{staticClass:"welcom"},[_vm._v("Hi, "+_vm._s(_vm.userInfo && _vm.userInfo.userInfo.nick_name))]),_c('p',{staticClass:"uid"},[_vm._v("UID:"+_vm._s(_vm.userInfo && _vm.userInfo.userInfo.bt_user_id))]),_c('div',{staticClass:"kyc"},[_c('img',{staticClass:"kyc-status",attrs:{"src":require("../../assets/img_n/ok_icon.png")}}),_c('span',{staticClass:"kyc-text"},[_vm._v(_vm._s(_vm.translateTitle("身份认证")))])])]),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"1"},on:{"click":function($event){_vm.goTo(
          _vm.routeSplicing(_vm.language, 'ucenter/situation'),
          'personal_overview'
        )}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/situation')}},[_c('i',{staticClass:"iconfont iconmenu-1"}),_vm._v(" "+_vm._s(_vm.translateTitle("个人总览"))+" ")])],1),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"2"},on:{"click":function($event){_vm.goTo(_vm.routeSplicing(_vm.language, 'ucenter/assets'), 'asset_management')}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/assets')}},[_c('i',{staticClass:"iconfont iconmenu-6 sub-submenu-i"}),_vm._v(" "+_vm._s(_vm.translateTitle("资产管理"))+" ")])],1),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"3"},on:{"click":function($event){_vm.goTo(
          _vm.routeSplicing(_vm.language, 'ucenter/tradeManagment'),
          'transaction_management'
        )}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/tradeManagment')}},[_c('i',{staticClass:"iconfont iconswap-1 sub-submenu-i"}),_vm._v(" "+_vm._s(_vm.translateTitle("交易管理"))+" ")])],1),(!_vm.is8Coin)?_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"4"},on:{"click":function($event){_vm.goTo(
          _vm.routeSplicing(_vm.language, 'ucenter/finanManagementTemp'),
          'financial_management'
        )}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/finanManagementTemp')}},[_c('i',{staticClass:"iconfont iconmenu-8 sub-submenu-i"}),_vm._v(" "+_vm._s(_vm.translateTitle("理财管理"))+" ")])],1):_vm._e(),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"5"},on:{"click":function($event){_vm.goTo(
          _vm.routeSplicing(_vm.language, 'ucenter/spread-center'),
          'promotion_center'
        )}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/spread-center')}},[_c('i',{staticClass:"iconfont iconicon-rebate"}),_vm._v(" "+_vm._s(_vm.translateTitle("推广中心"))+" ")])],1),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"7"},on:{"click":function($event){_vm.goTo(_vm.routeSplicing(_vm.language, 'ucenter/bill-center'), 'bill')}}},[_c('router-link',{attrs:{"active-class":"none-active","tag":"span","to":_vm.routeSplicing(_vm.language, 'ucenter/bill-center')}},[_c('i',{staticClass:"iconfont iconmenu-5"}),_vm._v(" "+_vm._s(_vm.translateTitle("账单"))+" ")])],1),_c('el-menu-item',{staticClass:"sub-item",attrs:{"index":"9"},on:{"click":_vm.onLogout}},[_c('span',{attrs:{"active-class":"none-active"}},[_c('i',{staticClass:"iconfont icontuichudenglu"}),_vm._v(" "+_vm._s(_vm.translateTitle("退出登录"))+" ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }