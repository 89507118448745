<template>
  <div class="brand-trade-data__container">
    <div class="brand-trade-data__container-title">
      {{ translateTitle("要交易 用4E") }}
    </div>
    <div class="brand-trade-data__container-tips">
      {{ translateTitle("一站式投资全球热门资产 投资无界限") }}
    </div>
    <div class="trade-chart__container-content-charts barnd">
      <div
        ref="animationEle"
        style="right: 0px"
        v-for="(items, index) in charts"
        :class="getRowClassName(index)"
        :key="index"
      >
        <div
          class="trade-chart__container-content-charts-rows-item"
          v-for="(item, index) in items"
          :key="index"
          @click="gotoExchange(item)"
        >
          <div class="trade-chart__container-content-charts-rows-item-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="trade-chart__container-content-charts-rows-item-info">
            <div
              class="trade-chart__container-content-charts-rows-item-info-title"
            >
              {{ item.title }}
            </div>

            <div
              :class="
                'trade-chart__container-content-charts-rows-item-info-values' +
                (item.isGrowth ? ' green' : ' red')
              "
            >
              <div
                class="trade-chart__container-content-charts-rows-item-info-values-value"
              >
                {{ item.value }}
              </div>
              <div
                class="trade-chart__container-content-charts-rows-item-info-values-growth"
              >
                {{ item.isGrowth ? "+" : "" }}{{ item.growth }}%
              </div>
            </div>
          </div>
          <div
            :ref="'echarts' + item.id"
            class="trade-chart__container-content-charts-rows-item-chart"
          ></div>
        </div>
        <div
          class="trade-chart__container-content-charts-rows-item"
          v-for="(item, index) in items"
          :key="index + '_chart'"
          @click="gotoExchange(item)"
        >
          <div class="trade-chart__container-content-charts-rows-item-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="trade-chart__container-content-charts-rows-item-info">
            <div
              class="trade-chart__container-content-charts-rows-item-info-title"
            >
              {{ item.title }}
            </div>

            <div
              :class="
                'trade-chart__container-content-charts-rows-item-info-values' +
                (item.isGrowth ? ' green' : ' red')
              "
            >
              <div
                class="trade-chart__container-content-charts-rows-item-info-values-value"
              >
                {{ item.value }}
              </div>
              <div
                class="trade-chart__container-content-charts-rows-item-info-values-growth"
              >
                {{ item.isGrowth ? "+" : "" }}{{ item.growth }}%
              </div>
            </div>
          </div>
          <div
            :ref="'echarts' + item.id + '_double'"
            class="trade-chart__container-content-charts-rows-item-chart"
          ></div>
        </div>
      </div>
      <div class="trade-chart__container-content-charts-mask left"></div>
      <div class="trade-chart__container-content-charts-mask right"></div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import chartDataMixin from "../../pages/home/mixins/chart-data";
export default {
  props: {},
  components: {},
  mixins: [commonMixin, chartDataMixin],
  data() {
    return {
      isBarnd: true,
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {
    isDarkMode: {
      handler(val) {
        this.initCharts(true);
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    getRowClassName(index) {
      const map = {
        0: "left16",
        1: "left18",
      };
      return "trade-chart__container-content-charts-rows" + " " + map[index];
    },
  },
};
</script>
<style lang="scss">
.trade-chart__container-content-charts.barnd {
  height: 250px;
  margin-top: 80px;
  .trade-chart__container-content-charts-rows {
    height: auto;
    margin-bottom: 40px;
    justify-content: center;
    width: auto;
    position: absolute;
    z-index: 1;
    &:nth-child(2) {
      top: 120px;
    }
    &.left16 {
      animation: showCharts 18s linear infinite;
    }
    &.left18 {
      animation: showCharts 20s linear infinite;
    }
  }
  .trade-chart__container-content-charts-rows {
  }
  .trade-chart__container-content-charts-rows-item {
    margin-right: 32px;
  }
}
.brand-trade-data__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 498px;
  background-color: var(--homeBgLightColor);
  /* background-image: var(
    --homeWroldImg
  ); //url(../../../assets/img/world-light.jpg); */
  color: var(--homeFontColor);
  &-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 18px;
  }
  &-tips {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: var(--homeLightGary);
  }
  &-items {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 264px;
      height: 132px;
      border: var(--homeGrayBorder);
      border-radius: 12px;
      padding: 24px;
      /* margin-left: 20px; */
      margin-top: 20px;
      &:hover {
        background: var(--homeHoverBgColor);
      }
      &-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 46px;
        margin-bottom: 10px;
        &-suffix {
          font-weight: 500;
          line-height: 48px;
          font-size: 32px;
          position: relative;
          left: -12px;
        }
      }
      &-subtitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #8b909b;
      }
    }
  }
}
</style>