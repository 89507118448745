<template>
  <div>
    <div class="coop-top-panel__container banner">
      <div class="coop-top-panel__container-banner">
        <div class="coop-top-panel__container-banner-title">
          {{ translateTitle("加入4E全球合伙人") }}
        </div>
        <div class="coop-top-panel__container-banner-subTitle">
          {{ translateTitle("享受业内顶级返佣，将影响力变现") }}
        </div>
        <div class="coop-top-panel__container-banner-tips">
          {{
            translateTitle(
              "如果您是内容创作者、社区群主、社媒博主等意见领袖，想要实现流量变现，立即申请加入4E合伙人计划并开始推广，即可享受业内顶级返佣条件。"
            )
          }}
        </div>
        <span @click="onJoin" class="coop-top-panel__container-banner-btn">{{
          translateTitle("立即加入")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";

export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    onJoin() {
      window.open("https://forms.gle/xNSDwfY6h1WheGKUA", "_blank");
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  --coopJoinBtnBgColor: #3348f6;
  --coopJoinBtnFontColor: #fff;
  .coop-top-panel__container.banner {
    background-image: url(../../assets/img/coop/banner.png);
  }
}
:root[theme="dark"] {
  --coopJoinBtnBgColor: #fff;
  --coopJoinBtnFontColor: #3348f6;
  .coop-top-panel__container.banner {
    background-image: url(../../assets/img/coop/banner-dark.png);
  }
}

.coop-top-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 498px; */
  background-color: var(--homeBgColor);
  &.banner {
    /* background-image: url(../../assets/img/coop/banner.png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  color: var(--homeFontColor);
  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
    height: 500px;
    /* background-image: url("../../../assets/images/home/ad-banner.png");
    background-size: cover;
    background-position: center; */
    &-title {
      color: var(--homeFontColor);
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px; /* 150% */
    }
    &-subTitle {
      color: var(--homeFontColor);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 125% */
      width: 560px;
    }
    &-tips {
      color: var(--homeLightGary);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 131.25% */
      margin-top: 12px;
      width: 560px;
    }
    &-btn {
      margin-top: 53px;
      display: flex;
      width: 300px;
      height: 52px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      border-radius: 99px;
      background: var(--coopJoinBtnBgColor);
      color: var(--coopJoinBtnFontColor);
      cursor: pointer;
    }
  }

  &-title {
    font-size: 36px;
    font-weight: 600;
    margin-top: 50px;
  }
}
</style>