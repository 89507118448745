import { Message } from 'element-ui';

export function showMessage(opts) {
  Message.closeAll();
  Message({
      type: opts.type || 'warning',
      position: 'top-center',
      title: opts.title || "__I18N(系统通知)__",
      message: opts.message,
      duration: opts.duration || 1500,
      showClose: true,
  });
}
