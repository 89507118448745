<template>
  <div>
    <div class="reason-panel__container">
      <div class="reason-panel__container-title">
        {{ translateTitle("选择4E的理由") }}
      </div>
      <div class="reason-panel__container-items">
        <div
          class="reason-panel__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="reason-panel__container-items-item-title">
            {{ translateTitle(item.title) }}
          </div>
          <div class="reason-panel__container-items-item-subtitle">
            {{ translateTitle(item.subTitle) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from "@/assets/img/coop/reason/icon1.png";
import icon2 from "@/assets/img/coop/reason/icon2.png";
import icon3 from "@/assets/img/coop/reason/icon3.png";

export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      items: [
        {
          title: "业内顶级返佣",
          subTitle: "返佣高达80%，行业遥遥领先",
          icon: icon1,
          // src/assets/img/home/ad/ad1.png
        },
        {
          title: "阿根廷国家队信赖之选",
          subTitle: "与 4E 官方合作伙伴阿根廷国家队同行",
          icon: icon2,
        },
        {
          title: "安全可靠的产品荟萃",
          subTitle:
            // "提供股票、現貨、合約等⼀站式投資服務，更多选择带来更高收入",
            "提供股票、现货、合约等⼀站式投资服务，更多选择带来更高收入",
          icon: icon3,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.reason-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  /* &.light-mode {
    background-color: var(--homeBgColor);
  } */
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      border: var(--homeGrayBorder);
      background: transparent;
      flex: 1;
      padding: 24px;
      box-sizing: content-box;
      &:hover {
        background: var(--homeHoverBgColor);
        border: 1px solid var(--Dark04-text-c_m, #465aff);
        background: linear-gradient(
          131deg,
          rgba(74, 202, 250, 0.08) 0%,
          rgba(236, 54, 235, 0.08) 100%
        );
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 32px;
      }
      > img {
        width: 120px;
        height: 120px;
      }
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 125% */
        margin-top: 32px;
      }
      &-subtitle {
        color: var(--homeLightGary);
        text-align: center;
        /* PF_font/r/t_r20 */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 130% */
        margin-top: 16px;
      }
    }
  }
}
</style>