<template>
  <div>
    <div class="honor-panel__container" :style="customStyle">
      <div class="honor-panel__container-mission">
        <div
          class="honor-panel__container-mission-item"
          v-for="(item, index) in mission"
          :key="index"
        >
          <div class="honor-panel__container-mission-item-container">
            <div class="honor-panel__container-mission-item-container-title">
              {{ translateTitle(item.title) }}
            </div>
            <div class="honor-panel__container-mission-item-container-subtitle">
              {{ translateTitle(item.subTitle) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mission1 from "@/assets/img/home/mission/mission1.png";
import mission2 from "@/assets/img/home/mission/mission2.png";

export default {
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
  },
  filters: {},
  directives: {},
  created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  components: {},
  mixins: [],
  data() {
    return {
      mission: [
        {
          title: "使命",
          subTitle: "成为全球综合金融服务平台的市场领导者",
          icon: mission1,
        },
        {
          title: "愿景",
          subTitle: "提供全球用户安全、便捷、创新的金融产品和服务",
          icon: mission2,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
:root[theme="light"] {
  .honor-panel__container-mission-item {
    background-image: url(../../assets/img/home/mission/1-light.png);
    &:nth-child(2) {
      background-image: url(../../assets/img/home/mission/2-light.png);
    }
  }
  .honor-panel__container-items-item-subtitle {
    color: var(--homeLightGary);
  }
}

#app:not(.zh_CN, .zh_HK) {
  .honor-panel__container-mission-item-container-title {
    position: relative;
    top: 10px;
  }
}

:root[theme="dark"] {
}
.honor-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  &.light-mode {
    background-color: var(--homeBgColor);
  }
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }
  &-mission {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 1200px;
    &-item {
      position: relative;
      min-height: 428px;
      flex: 1;
      background-image: url(../../assets/img/home/mission/1.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 12px;
      overflow: hidden;
      &:nth-child(2) {
        background-image: url(../../assets/img/home/mission/2.png);
      }
      &:hover {
        /* transform: scale(1.02); */
        animation: expendSize 0.5s linear;
        animation-fill-mode: forwards;
        /* background-size: 110% 110%; */
      }
      &:hover .honor-panel__container-mission-item-container {
        transform: translate3d(0px, 0px, 0px);
        height: auto;
      }
      &:hover .honor-panel__container-mission-item-container-subtitle {
        transform: translate3d(0, 0, 0);
        color: #fff;
      }
      &:nth-child(1) {
        margin-right: 26px;
      }

      &-container {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding-left: 12px;
        padding-top: 20px;
        padding-bottom: 20px; */
        padding: 24px;
        border-radius: 0 0 12px 12px;
        color: #fff;
        height: 106px;
        transform: translate3d(0px, 26px, 0px);
        transition: all 0.5s;
        &-title {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
        }
        &-subtitle {
          transform: translate3d(0, 100%, 0);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          color: transparent;
          transition: all 0.5s;
          margin-top: 12px;
        }
      }
    }
  }
}
@keyframes expendSize {
  0% {
    background-size: 102% 102%;
  }
  20% {
    background-size: 104% 104%;
  }
  40% {
    background-size: 106% 106%;
    border-radius: 12px;
  }
  80% {
    background-size: 108% 108%;
  }
  100% {
    background-size: 110% 110%;
    border-radius: 12px;
  }
}
</style>