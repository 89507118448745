<template>
  <div>
    <!-- <top-banner @trigger-download="openDownload" /> -->
    <home-top-banner @trigger-download="openDownload" />
    <trade-data />
    <trade-chart @trigger-download="openDownload" />
    <ad-panel />
    <feature-panel />
    <!-- <mission-panel /> -->
    <honor-panel />
    <download-panel />
    <hot-panel />
    <register-panel v-if="!userInfoShow" />
    <faq-panel :customStyle="faqCustomStyle" />
    <download-popup
      :downloadTipsVisible="downloadTipsVisible"
      :closeDownloadTipsDialog="closeDownloadTipsDialog"
    ></download-popup>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import AdPanel from "@/components/home/ad-panel.vue";
import DownloadPanel from "@/components/home/download-panel.vue";
import FaqPanel from "@/components/home/faq-panel.vue";
import HonorPanel from "@/components/home/honor-panel.vue";
import HotPanel from "@/components/home/hot-panel.vue";
import RegisterPanel from "@/components/home/register-panel.vue";
import TradeChart from "@/components/home/trade-chart.vue";
import TradeData from "@/components/home/trade-data.vue";
import FeaturePanel from "@/components/home/feature-panel.vue";
// import MissionPanel from "@/components/home/mission-panel.vue";
import DownloadPopup from "@/components/common/download-popup.vue";
import HomeTopBanner from "@/components/home/home-top-banner.vue";

export default {
  props: {},
  components: {
    TradeData,
    TradeChart,
    AdPanel,
    HonorPanel,
    DownloadPanel,
    HotPanel,
    RegisterPanel,
    FaqPanel,
    FeaturePanel,
    // MissionPanel,
    DownloadPopup,
    HomeTopBanner,
  },
  mixins: [],
  data() {
    return {
      faqCustomStyle: {},
      downloadTipsVisible: false,
    };
  },
  async created() {
    this.faqCustomStyle = this.getBgColor(this.userInfoShow);
  },
  destroyed() {},
  beforeDestroy() {},
  watch: {
    isDarkMode() {
      this.faqCustomStyle = this.getBgColor(this.userInfoShow);
    },
  },
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    reportHomepageClick(module, type) {
      this.$sensors.track("Homepage_Click", {
        of_module: module,
        button_name: type ? type : "--",
      });
    },
    openDownload() {
      // let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      // window.open(tempUrl);
      this.downloadTipsVisible = true;

      this.reportHomepageClick("title", "mock_trade");
    },
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },
    getBgColor(isLight) {
      const rootElement = document.documentElement;
      const computedStyle = getComputedStyle(rootElement);

      const homeBgLightColor =
        computedStyle.getPropertyValue("--homeBgLightColor");
      const homeBgColor = computedStyle.getPropertyValue("--homeBgColor");
      return {
        backgroundColor: isLight ? homeBgLightColor : homeBgColor,
      };
    },
  },
};
</script>
<style lang="scss">
</style>
