<template>
  <div class="top-banner__container home-top">
    <div class="banner__container-content">
      <div class="top-banner__container-title">
        {{ translateTitle("4E交易所 赢在未来") }}
      </div>
      <div class="top-banner__container-btns">
        <span
          v-if="!userInfoShow"
          @click="onResiter"
          class="top-banner__container-btns-register"
          >{{ translateTitle("立即注册") }}</span
        >
        <span
          v-if="userInfoShow"
          @click="onTrade"
          class="top-banner__container-btns-register"
          >{{ translateTitle("立即交易") }}</span
        >
        <span
          v-if="!userInfoShow"
          @click="onLogin"
          class="top-banner__container-btns-login mock-trade"
          >{{ translateTitle("登录") }}</span
        >
      </div>
    </div>
    <div class="scroll-img scroll-img-1">
      <img src="../../assets/img/home/banners/1.png" />
      <img src="../../assets/img/home/banners/1.png" />
    </div>
    <div class="scroll-img scroll-img-2">
      <img src="../../assets/img/home/banners/2.png" />
      <img src="../../assets/img/home/banners/2.png" />
    </div>
    <div class="scroll-img scroll-img-3">
      <img src="../../assets/img/home/banners/3.png" />
      <img src="../../assets/img/home/banners/3.png" />
    </div>
    <div class="scroll-img scroll-img-4">
      <img src="../../assets/img/home/banners/4.png" />
      <img src="../../assets/img/home/banners/4.png" />
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import Cookies from "js-cookie";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    routeSplicing,

    reportHomepageClick(module, type) {
      this.$sensors.track("Homepage_Click", {
        of_module: module,
        button_name: type ? type : "--",
      });
    },
    mockTrade() {
      // let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      // window.open(tempUrl);
      // this.downloadTipsVisible = true;
      // this.reportHomepageClick("title", "mock_trade");
      this.$emit("trigger-download");
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
      this.reportHomepageClick("title", "trade_now");
    },
    onLogin() {
      this.$router.push({
        name: "Login",
        params: {},
      });
    },
    onResiter() {
      this.$router.push({
        name: "Register",
        params: {},
      });

      this.$sensors.track("Register_Click", {
        invite_code: "",
        channel_type: "自然流量",
        second_channel_type: "WEB",
      });
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .top-banner__container-btns-login.mock-trade:hover {
    background: var(--homeHoverBgColor) !important;
  }
}
:root[theme="dark"] {
  .top-banner__container-btns-login.mock-trade:hover {
    background: rgb(255, 255, 255, 0.06) !important;
  }
}
.top-banner__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: var(--homeBgColor);
  background-image: url(../../assets/img/home/banners/top-banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--homeFontColor);
  &.home-top {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    background-size: contain;
    background-position: left;
    .banner__container-content {
      width: 1200px;
      .top-banner__container-btns {
        justify-content: start;
      }
    }
    .scroll-img {
      position: absolute;
      width: 100px;
      img {
        &:last-child {
          margin-top: 28px;
        }
      }
      &-1,
      &-3 {
        top: 0;
        right: 650px;
        transform: translate3d(0, 0, 0);
        animation: moveSlideshow 24s linear infinite;
      }
      &-2,
      &-4 {
        bottom: 0;
        right: 500px;
        transform: translate3d(0, 0, 0);
        animation: moveSlideshow2 24s linear infinite;
      }
      &-3 {
        right: 350px;
      }

      &-4 {
        right: 200px;
      }
    }
  }
  &-title {
    line-height: 60px;
    font-size: 48px;
    font-weight: 600;
    max-width: 510px;
  }
  &-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    &-register,
    &-login {
      cursor: pointer;
      border-radius: 26px;
      padding: 0px 16px;
      height: 52px;
      line-height: 52px;
      min-width: 150px;
      text-align: center;
    }
    &-register {
      /* padding: 10px 30px;
      border-radius: 5px; */
      /* border: 1px solid var(--homeFontColor); */
      margin-right: 16px !important;
      background: var(--homeColorBtnBgColor);
      color: var(--homeColorBtnColor);
    }
    &-login {
      /* padding: 10px 30px;
      border-radius: 5px; */
      border: 1px solid var(--homeLightBtnBorder);
      background-color: var(--homeLightBtnBgColor);
      color: var(--homeLightBtnColor);
      border-radius: 26px;
      border: 1px solid var(--Light04-border-c_s_b01, #cbcbcb);
      background: none !important;
    }
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translateY(-50%);
  }
}
@keyframes moveSlideshow2 {
  100% {
    transform: translateY(50%);
  }
}
</style>
