<template>
  <div class="trade-chart__container">
    <div class="trade-chart__container-content">
      <div class="trade-chart__container-content-navs">
        <div
          :class="
            'trade-chart__container-content-navs-nav' +
            (activeNav === item.id ? ' active' : '')
          "
          v-for="(item, index) in navs"
          :key="index"
          @click="switchNav(item)"
        >
          <div class="trade-chart__container-content-navs-nav-title">
            {{ translateTitle(item.title) }}
          </div>
          <div class="trade-chart__container-content-navs-nav-subTitle">
            {{ translateTitle(item.subTitle) }}
          </div>
        </div>
      </div>
      <div class="trade-chart__container-content-charts">
        <div
          ref="animationEle"
          v-for="(items, index) in charts"
          :class="getClassName(index)"
          :key="index"
        >
          <div
            class="trade-chart__container-content-charts-rows-item"
            v-for="(item, index) in items"
            :key="index"
            @click="gotoExchange(item)"
          >
            <div class="trade-chart__container-content-charts-rows-item-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="trade-chart__container-content-charts-rows-item-info">
              <div
                class="trade-chart__container-content-charts-rows-item-info-title"
              >
                {{ item.title }}
              </div>

              <div
                v-if="item.value !== undefined"
                :class="
                  'trade-chart__container-content-charts-rows-item-info-values' +
                  (item.isGrowth ? ' green' : ' red')
                "
              >
                <div
                  class="trade-chart__container-content-charts-rows-item-info-values-value"
                >
                  {{ item.value }}
                </div>
                <div
                  class="trade-chart__container-content-charts-rows-item-info-values-growth"
                >
                  {{ item.isGrowth ? "+" : "" }}{{ item.growth }}%
                </div>
              </div>
            </div>
            <div
              :ref="'echarts' + (item.id || item.title)"
              class="trade-chart__container-content-charts-rows-item-chart"
            ></div>
          </div>
          <div
            class="trade-chart__container-content-charts-rows-item"
            v-for="(item, index) in items"
            @click="gotoExchange(item)"
            :key="index + '_chart'"
          >
            <div class="trade-chart__container-content-charts-rows-item-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="trade-chart__container-content-charts-rows-item-info">
              <div
                class="trade-chart__container-content-charts-rows-item-info-title"
              >
                {{ item.title }}
              </div>

              <div
                v-if="item.value !== undefined"
                :class="
                  'trade-chart__container-content-charts-rows-item-info-values' +
                  (item.isGrowth ? ' green' : ' red')
                "
              >
                <div
                  class="trade-chart__container-content-charts-rows-item-info-values-value"
                >
                  {{ item.value }}
                </div>
                <div
                  class="trade-chart__container-content-charts-rows-item-info-values-growth"
                >
                  {{ item.isGrowth ? "+" : "" }}{{ item.growth }}%
                </div>
              </div>
            </div>
            <div
              :ref="'echarts' + (item.id || item.title) + '_double'"
              class="trade-chart__container-content-charts-rows-item-chart"
            ></div>
          </div>
        </div>
        <div class="trade-chart__container-content-charts-mask left"></div>
        <div class="trade-chart__container-content-charts-mask right"></div>
      </div>
    </div>
    <div class="trade-chart__container-more">
      <a href="/markets">{{ translateTitle("查看更多") }}</a>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import chartDataMixin from "../../pages/home/mixins/chart-data";
export default {
  props: {},
  components: {
    // "v-chart": ECharts,
  },
  mixins: [commonMixin, chartDataMixin],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {
    isDarkMode: {
      handler(val) {
        this.initCharts(true);
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    onDarkModeChange(isDarkMode) {
      this.initCharts(true);
    },
    switchNav(item) {
      this.activeNav = item.id;
      const elements = this.$refs.animationEle || [];
      elements.forEach((element) => {
        // 停止当前正在运行的动画
        element.style.animationPlayState = "paused";
        element.style.animationName = "none";
        // element.style.right = "1000%";
        // 设置动画结束时的最终状态
        // 让动画完成后立即结束
        // element.offsetWidth = element.offsetWidth;
        // 重新设置animation-name以开始动画
        setTimeout(() => {
          element.style.animationName = "showCharts";
          element.style.animationPlayState = "running";
        }, 300);
      });
      this.updateCharts(this[item.key], item.key);
    },
  },
};
</script>
<style lang="scss">
:root[theme="dark"] {
  .trade-chart__container-content-charts-mask {
    &.left {
      background: linear-gradient(
        90deg,
        rgba(9, 9, 19, 1) 0%,
        rgba(9, 9, 19, 0.75) 25%,
        rgba(9, 9, 19, 0.5) 50%,
        rgba(9, 9, 19, 0.25) 75%,
        rgba(9, 9, 19, 0) 100%
      );
    }
    &.right {
      background: linear-gradient(
        90deg,
        rgba(9, 9, 19, 0) 0%,
        rgba(9, 9, 19, 0.25) 25%,
        rgba(9, 9, 19, 0.5) 50%,
        rgba(9, 9, 19, 0.75) 75%,
        rgba(9, 9, 19, 1) 100%
      );
    }
  }
}
.trade-chart__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 588px; */
  background-color: var(--homeChartBgColor);
  color: var(--homeFontColor);
  padding: 100px 0;
  &-more {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* height: 64px; */
    margin-top: 48px;
    > a {
      color: #3348f6;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    width: 1200px;

    &-navs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 326px;
      height: 632px;
      &-nav {
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        flex: 1;
        width: 326px;
        background: var(--homeChartNavBgColor);
        color: var(--homeFontColorLight);
        height: 92px;
        padding: 16px;
        padding-right: 8px;
        border-radius: 8px;
        margin-bottom: 10px;
        cursor: pointer;
        border: 1px solid transparent;
        &.active {
          border: 1px solid var(--homeChartNavBorderColor);
          color: var(--homeChartNavActiveColor);
        }
        &-title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 30px;
        }
        &-subTitle {
          font-size: 14px;

          line-height: 18px;
        }
      }
    }
    &-charts {
      width: 100%;
      height: auto;
      margin-left: 40px;
      background-color: var(--homeChartBgColor);
      position: relative;
      overflow: hidden;
      &-mask {
        position: absolute;
        top: 0;
        /* left: 0; */
        width: 60px;
        height: 100%;

        z-index: 1;
        &.left {
          left: 0;
          width: 120px;
          background: linear-gradient(
            90deg,
            #fbfcff 0%,
            #fbfcff 46.44%,
            rgba(251, 252, 255, 0) 100%
          );
        }
        &.right {
          right: 0;
          background: linear-gradient(
            90deg,
            rgba(251, 252, 255, 0) 0%,
            rgba(251, 252, 255, 0.25) 25%,
            rgba(251, 252, 255, 0.5) 50%,
            rgba(251, 252, 255, 0.75) 75%,
            rgba(251, 252, 255, 1) 100%
          );
        }
      }
      &-rows {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        position: relative;
        margin-bottom: 10px;
        height: auto;
        margin-bottom: 48px;
        justify-content: center;
        width: auto;
        position: absolute;
        right: 0;
        z-index: 1;
        transform: translate3d(0, 0, 0);
        &:nth-child(2) {
          top: 131px;
        }
        &:nth-child(3) {
          top: 265px;
        }
        &:nth-child(4) {
          top: 400px;
        }
        &:nth-child(5) {
          top: 530px;
        }

        &.left120 {
          animation: showCharts 16s linear infinite;
        }
        &.left278 {
          animation: showCharts 12s linear infinite;
        }
        &.left695 {
          animation: showCharts 10s linear infinite;
        }
        &.left855 {
          animation: showCharts 15s linear infinite;
        }
        &.left1005 {
          animation: showCharts 13s linear infinite;
        }

        /* animation: name duration timing-function delay iteration-count direction fill-mode; */
        &-item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          /* width: 100%; */
          min-width: 398px;
          height: 92px;
          border: var(--homeChartBorder);
          border-radius: 12px;
          padding-left: 20px;
          padding-right: 8px;
          margin-right: 32px;
          &-icon {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
          &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* align-items: center; */
            flex: 1;
            height: 100%;
            /* background-color: var(--homeBgLightColor); */
            &-title {
              color: var(--homeFontColor);
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              width: max-content;
            }
            &-values {
              display: flex;
              flex-direction: row;

              color: var(--Dark04-text-c_r, #f0551c);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: max-content;
              &.green {
                color: #31bd50;
              }
              &.red {
                color: #f0551c;
              }
              &-value {
                margin-right: 10px;
              }
            }
          }
          &-chart {
            height: 90px;
            width: 210px;
          }
        }
      }
    }
  }
}
@keyframes showCharts {
  /* 0% {
    left: 0;
  } */

  100% {
    transform: translateX(50%);
  }
}
</style>