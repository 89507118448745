<template>
  <div class="page-header-8coin">
    <div class="left-pages">
      <a :href="homeUrl" @click="reportTabClick('logo', '')">
        <img :draggable="false" class="logo" :src="logImg" alt />
      </a>
      <ul class="pages-routes">
        <!-- <a
          :href="routeSplicing(language, `markets`)"
          @click="reportTabClick('markets', '')"
        >
          <li :class="active == 1 ? 'active' : ''">
            <a
              :href="routeSplicing(language, `markets`)"
              style="margin-right: 5px"
              >{{ translateTitle("行情") }}</a
            >
          </li>
        </a> -->

        <!-- 新菜单开始 -->
        <a @mouseenter="mouseenterTrade" @mouseleave="mouseleaveTrade">
          <li :class="active == 2 ? 'active' : ''">
            <a
              >{{ translateTitle("交易") }}
              <i
                class="iconfont icon-top"
                :class="activeTrade ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeTrade">
            <a
              class="it"
              :href="routeSplicing(language, 'exchange/btc_usdt')"
              @click="reportTabClick('trade', 'spot_trading')"
            >
              <img src="../../assets/img_n/theme/coinex8/bbjy.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("现货交易") }} </span>
                <span class="st"
                  >{{ translateTitle("便捷交易数字货币") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>
            <div
              class="it"
              @click="onDownloadTipsDialog('derivatives', 'u_future')"
            >
              <img src="../../assets/img_n/theme/coinex8/ubw.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("合约交易") }} </span>
                <span class="st"
                  >{{ translateTitle("以USDT结算的永续合约") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog('trade', 'C2C')">
              <!-- <div class="it"> -->
              <img src="../../assets/img_n/theme/coinex8/otc.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("C2C") }} </span>
                <span class="st"
                  >{{ translateTitle("秒速入金，即刻开始") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog('trade', 'stock')">
              <img src="../../assets/img_n/theme/coinex8/gp.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("股票") }} </span>
                <span class="st"
                  >{{ translateTitle("无门槛，快速投资股市") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <!-- <div class="it" @click="onDownloadTipsDialog('trade', 'exchange')">
              <img src="../../assets/img_n/theme/coinex8/hd.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("汇兑") }} </span>
                <span class="st"
                  >{{ translateTitle("法币、数字货币快速兑换") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div
              class="it"
              @click="onDownloadTipsDialog('trade', 'strategy_trading')"
            >
              <img src="../../assets/img_n/theme/coinex8/cljy.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("策略交易") }} </span>
                <span class="st">{{ translateTitle("定投数字货币") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div> -->
          </div>
        </a>

        <a @mouseenter="mouseenterDeri" @mouseleave="mouseleaveDeri">
          <li :class="active == 3 || active == 6 ? 'active' : ''">
            <a
              >{{ translateTitle("衍生品") }}
              <i
                class="iconfont icon-top"
                :class="activeDeri ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeDeri">
            <div
              class="it"
              @click="onDownloadTipsDialog('derivatives', 'forex')"
            >
              <img src="../../assets/img_n/theme/coinex8/wh.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("外汇") }} </span>
                <span class="st"
                  >{{ translateTitle("全球货币期货交易") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div
              class="it"
              @click="onDownloadTipsDialog('derivatives', 'contract')"
            >
              <img src="../../assets/img_n/theme/coinex8/qh.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("大宗") }} </span>
                <span class="st"
                  >{{ translateTitle("商品大宗交易衍生品") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div
              class="it"
              @click="onDownloadTipsDialog('derivatives', 'stock_index')"
            >
              <img src="../../assets/img_n/theme/coinex8/gz.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("股指") }} </span>
                <span class="st">{{ translateTitle("股市指标衍生品") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>
        <a @mouseenter="mouseenterMore" @mouseleave="mouseleaveMore">
          <li :class="active == 5 ? 'active' : ''">
            <a
              >{{ translateTitle("更多") }}
              <i
                class="iconfont icon-top"
                :class="activeMore ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeMore">
            <div
              class="it"
              @click="onDownloadTipsDialog('more', 'copy_trading')"
            >
              <img src="../../assets/img_n/theme/coinex8/gd.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("跟单") }} </span>
                <span class="st"
                  >{{ translateTitle("实时跟随交易大咖") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div
              class="it"
              @click="onDownloadTipsDialog('more', 'demo_account')"
            >
              <img src="../../assets/img_n/theme/coinex8/mnp.png" class="i" />

              <div class="t-st">
                <span class="t">{{ translateTitle("模拟盘") }} </span>
                <span class="st"
                  >{{ translateTitle("真实合约交易模拟") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div
              class="it"
              @click="onDownloadTipsDialog('more', 'demo_account')"
            >
              <img
                src="../../assets/img_n/theme/coinex8/debit-card.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("金融卡") }} </span>
                <span class="st"
                  >{{ translateTitle("全球消费 轻松支付") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>
        <a
          :href="routeSplicing(language, `cooperation`)"
          @click="reportTabClick('cooperation', '')"
        >
          <li :class="active == 1 ? 'active' : ''">
            <a
              :href="routeSplicing(language, `cooperation`)"
              style="margin-right: 5px"
              >{{ translateTitle("合作推广") }}</a
            >
          </li>
        </a>
        <!-- <a
          :href="routeSplicing(language, 'brand')"
          @click="reportTabClick('brand', 'brand')"
        >
          <li :class="active == 1 ? 'active' : ''">
            <a
              :href="routeSplicing(language, `brand`)"
              style="margin-right: 5px"
              >{{ translateTitle("关于4E") }}</a
            >
          </li>
        </a> -->
        <!-- <a
          :href="routeSplicing(language, `markets`)"
          @click="reportTabClick('markets', '')"
        >
          <li :class="active == 1 ? 'active' : ''">
            <a href="/#hot-information" style="margin-right: 5px">{{
              translateTitle("市场热点")
            }}</a>
          </li>
        </a> -->
        <a @mouseenter="mouseenterAbout" @mouseleave="mouseleaveAbout">
          <li :class="active == 2 ? 'active' : ''">
            <a
              >{{ translateTitle("关于4E") }}
              <i
                class="iconfont icon-top"
                :class="activeTrade ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeAbout">
            <a
              class="it"
              :href="routeSplicing(language, 'fund')"
              @click="reportTabClick('fund', 'fund')"
            >
              <img src="../../assets/img_n/theme/coinex8/fund.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("风险基金") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>
            <a
              class="it"
              :href="routeSplicing(language, 'brand')"
              @click="reportTabClick('brand', 'brand')"
            >
              <img src="../../assets/img_n/theme/coinex8/about.png" class="i" />
              <div class="t-st">
                <span class="t">{{ translateTitle("关于我们") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>
          </div>
        </a>
        <!-- <a
          :href="routeSplicing(language, `brand`)"
          @click="reportTabClick('brand', '')"
        >
          <li :class="active == 1 ? 'active' : ''">
            <a
              :href="routeSplicing(language, `brand`)"
              style="margin-right: 5px"
              >{{ translateTitle("关于4E") }}</a
            >
          </li>
        </a> -->
      </ul>
    </div>
    <div class="right-menus">
      <span
        class="station-name"
        v-if="app_env == 'test' || app_env == undefined"
      >
        {{ envName }}
      </span>

      <span
        v-if="userInfoShow"
        class="quick-access"
        @click="reportTabClick('assets', '')"
      >
        <a :href="routeSplicing(language, `ucenter/assets`)">
          {{ translateTitle("资产") }}
        </a>
      </span>
      <span
        v-if="userInfoShow"
        class="quick-access"
        @click="reportTabClick('order', '')"
      >
        <a :href="routeSplicing(language, `ucenter/tradeManagment`)">
          {{ translateTitle("订单") }}
        </a>
      </span>
      <el-menu class="right-menu" mode="horizontal" @select="handleSelect">
        <template>
          <!-- 个人中心 -->
          <user-menu></user-menu>
        </template>
        <template>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item login" v-show="!userInfoShow">
            <span @click="onLogin">
              <router-link
                active-class="none-active"
                :to="routeSplicing(language, 'login')"
                >{{ translateTitle("登录") }}</router-link
              >
            </span>
          </el-menu-item>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item register" v-show="!userInfoShow">
            <span @click="onRegister">
              <router-link
                active-class="none-active"
                :to="{
                  path: routeSplicing(language, 'register'),
                  query: this.$route.query,
                }"
              >
                {{ translateTitle("注册") }}
              </router-link>
            </span>
          </el-menu-item>
        </template>
        <template>
          <li class="vertical-line-li">
            <span class="vertical-line"></span>
          </li>
        </template>
        <template>
          <li
            class="download-app"
            @mouseenter="onShowDownLoad"
            @mouseleave="onHiddenDownLoad"
          >
            <span class="download-app-text">
              <!-- <i class="iconfont iconicon-hd-3"></i> -->
              <img :src="downIcon" class="down_icon" />
              {{ translateTitle("APP下载") }}</span
            >
            <div v-show="showDownLoadImg" class="down-box">
              <div>
                <p class="down-load-text">
                  {{ translateTitle("手机扫码下载APP，即刻开启交易") }}
                </p>
                <span class="img-content">
                  <div class="down-load-url-outer">
                    <qrcode-vue
                      class="down-load-url"
                      v-show="oriUrl"
                      :value="oriUrl"
                      :size="100"
                      level="H"
                    ></qrcode-vue>
                  </div>
                  <p class="left-item">
                    <span class="down-load-item-text">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img_n/apple_down.png"
                        alt=""
                      />
                      <span class="down-text"
                        >iOS {{ translateTitle("下载") }}</span
                      >
                    </span>
                    <span class="down-load-item-text V2">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img_n/android_down.png"
                        alt=""
                      />
                      <span class="down-text"
                        >Android {{ translateTitle("下载") }}</span
                      >
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </li>
        </template>
        <!-- 法币 -->
        <li
          @click="onMaskCurrencyDialog"
          class="el-submenu sub-item-wrap select-lang-masks currency"
          style="margin-right: 20px"
        >
          <!-- <span>
            {{ language_name }}
          </span> -->
          <!-- <span>/</span> -->
          <span>
            {{ currencys.toUpperCase() }}
          </span>
          <img :src="expandIcon" class="expand" />
        </li>
        <!-- 语言 -->
        <li
          @click="onShowLangDialog"
          class="el-submenu sub-item-wrap select-lang-masks"
        >
          <span>
            {{ language_name }}
          </span>
          <!-- <span>/</span>
          <span>
            {{ currencys.toUpperCase() }}
          </span> -->
          <img :src="expandIcon" class="expand" />
        </li>
        <!-- 主题模式-->
        <template>
          <li class="download-app theme">
            <span class="download-app-text">
              <theme-switch />
            </span>
          </li>
        </template>
      </el-menu>
    </div>

    <el-dialog
      custom-class="langVisible_n"
      :visible.sync="langVisible"
      :show-close="false"
    >
      <img
        src="../../assets/img_n/close_icon.png"
        class="close_icon"
        @click="
          () => {
            langVisible = false;
          }
        "
      />
      <el-tabs v-model="activeName">
        <el-tab-pane :label="translateTitle('语言和地区')" name="first">
          <p class="pane-title">{{ translateTitle("选择语言和地区") }}</p>
          <ul>
            <li
              v-for="item in countryList"
              :key="item.value"
              @click="onLangClick(item)"
            >
              <span :class="languageName == item.name ? 'red-lang-curr' : ''">{{
                item.name
              }}</span>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane :label="translateTitle('法币')" name="second">
          <p class="pane-title">{{ translateTitle("选择法币") }}</p>
          <ul>
            <li
              v-for="(item, index) in currencyMaps"
              :key="index"
              @click="onCurrencyChange(item.logic_name)"
            >
              <span :class="currency == item.abbreviate ? 'red-lang-curr' : ''">
                {{ `${item.mark} ${item.abbreviate}` }}
              </span>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <download-popup
      :downloadTipsVisible="downloadTipsVisible"
      :closeDownloadTipsDialog="closeDownloadTipsDialog"
    ></download-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getLanguage } from "@/i18n/index.js";

import Cookies from "js-cookie";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import QrcodeVue from "qrcode.vue";
import UserMenu from "./UserMenu";
import { apiGetStaticTableInfo } from "@/model/settings";
import zhCN from "../../assets/img/lang/f-cn.png";
import zhHK from "../../assets/img/lang/f-hk.png";
import enUS from "../../assets/img/lang/f-en.png";
import Ja from "../../assets/img/lang/f-ja.png";
import Ko from "../../assets/img/lang/f-ko.png";

import ar from "../../assets/img/lang/f-ar.png";
import fr from "../../assets/img/lang/f-fr.png";
import es from "../../assets/img/lang/f-es.png";
import ru from "../../assets/img/lang/f-ru.png";
import de from "../../assets/img/lang/f-de.png";
import pt from "../../assets/img/lang/f-pt.png";
import hi from "../../assets/img/lang/f-hi.png";
import vi from "../../assets/img/lang/f-vi.png";
import tl from "../../assets/img/lang/f-tl.png";
import th from "../../assets/img/lang/f-th.png";
import tr from "../../assets/img/lang/f-tr.png";
import it from "../../assets/img/lang/f-it.png";
import id from "../../assets/img/lang/f-id.png";
import {
  VUE_APP_ENV,
  WEB_OPEN_COINM,
  DEVICE_ID,
  VUE_CHANNEL_ID,
} from "@/assets/js/stationSetting";
import { apiWsUrl } from "@/model/exchange/index";
import { getLangPackage } from "@/assets/js/lang";
import routeSplicing from "@/assets/js/routeSplicing";
import ThemeSwitch from "./theme-switch.vue";
import DownloadPopup from "./download-popup.vue";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    UserMenu,
    QrcodeVue,
    ThemeSwitch,
    DownloadPopup,
  },
  data() {
    return {
      WEB_OPEN_COINM,
      homeUrl: "",
      app_env: "",
      languageName: "English",
      currency: "CNY",
      appDownLoadImg: "",
      appstore_url: "",
      tipUrl: "",
      google_play_url: "",
      langVisible: false,
      showDownLoadImg: false,
      downloadTipsVisible: false,
      currencyMaps: {},
      logImg: require("../../assets/img/logo_8coin.png"),
      imgUrl: require("../../assets/img/en.png"),
      downIcon: require("../../assets/img_n/mobile.png"),
      expandIcon: require("../../assets/img_n/expand.png"),
      countryList: [
        {
          name: "English",
          value: "en",
          img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          img: fr,
        },
        {
          name: "Español",
          value: "es",
          img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          img: de,
        },
        {
          name: "Português",
          value: "pt",
          img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          img: th,
        },
        {
          name: "Türk",
          value: "tr",
          img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          img: it,
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          img: id,
        },
        {
          name: "عربي",
          value: "ar",
          img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          img: zhHK,
        },
      ],
      activeName: "first",
      android_url_new: "",
      appstore_url_new: "",
      activeTrade: false,
      activeAbout: false,
      activeDeri: false,
      activeEarn: false,
      activeMore: false,
      oriUrl: "",
      downloadOrigin: "",
    };
  },
  created() {
    this.homeUrl = `/${this.language}`;
    this.app_env = VUE_APP_ENV;
    this.initURL();

    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    this.appDownLoadImg = tempUrl.url;
    this.appstore_url = tempUrl.appstore_url;
    this.google_play_url = tempUrl.google_play_url;
    let temp_url = tempUrl.ori_url;
    // app下载二维码埋点
    if (this.userInfo) {
      this.appDownLoadImg = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=${this.userInfo.user_id}`;
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=${this.userInfo.user_id}`;
    } else {
      this.appDownLoadImg = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=`;
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=`;
    }
    let currency =
      localStorage.getItem("currency") ||
      (window.location.hostname.includes("4e.inc") ? "vnd" : "cny");
    // let currency =
    //   localStorage.getItem("currency") ||
    //   (window.location.hostname.includes("localhost") ? "vnd" : "cny");
    // let broLang = "";
    // let navLang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
    // navLang = navLang.substr(0, 2); //截取lang前2位字符
    // navLang = navLang.toLowerCase();
    // if (navLang == "zh") {
    //   broLang = "zh_CN";
    // } else {
    //   broLang = navLang;
    // }
    let tempLang = localStorage.getItem("language") || getLanguage();
    if (tempLang) {
      this.countryList.forEach(ele => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    } else {
      tempLang = "zh_CN";

      this.countryList.forEach(ele => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    }

    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }

    this.handleSelect(currency);
  },
  mounted() {
    this.getStaticChainInfo();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      currencys: "user/currency",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
    language_name() {
      let name = "";
      this.countryList.forEach(item => {
        if (item.value == this.language) {
          name = item.name;
        }
      });
      return name;
    },
    envName() {
      let tempVal = localStorage.getItem("environment");
      return tempVal;
    },
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    active() {
      let val = 0;
      if (this.$route.path.includes("/markets")) {
        val = 1;
      } else if (this.$route.path.includes("/exchange")) {
        val = 2;
      } else if (this.$route.path.includes("/contract-exchange")) {
        val = 3;
      } else if (this.$route.path.includes("/finances")) {
        val = 4;
      } else if (this.$route.path.includes("/mining-pool")) {
        val = 5;
      } else if (this.$route.path.includes("/delivery")) {
        val = 6;
      } else if (this.$route.path.includes("/quicklycoins")) {
        val = 7;
      } else {
        val = 0;
      }
      return val;
    },
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    //新菜单开始

    mouseenterAbout() {
      this.activeAbout = true;
    },
    mouseleaveAbout() {
      this.activeAbout = false;
    },
    mouseenterTrade() {
      this.activeTrade = true;
    },
    mouseleaveTrade() {
      this.activeTrade = false;
    },
    mouseenterDeri() {
      this.activeDeri = true;
    },
    mouseleaveDeri() {
      this.activeDeri = false;
    },
    mouseenterEarn() {
      this.activeEarn = true;
    },
    mouseleaveEarn() {
      this.activeEarn = false;
    },
    mouseenterMore() {
      this.activeMore = true;
    },
    mouseleaveMore() {
      this.activeMore = false;
    },
    onDownloadTipsDialog(origin, from) {
      this.downloadOrigin = from;
      this.downloadTipsVisible = true;
      this.reportTabClick(origin, from);
    },
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },
    onLogin() {
      this.$sensors.track("Login_Click", {
        current_page_url: window.location.href,
        current_page: "首页",
      });
    },
    onRegister() {
      this.$sensors.track("Register_Click", {
        invite_code: "",
        channel_type: "自然流量",
        second_channel_type: "WEB",
      });
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.android_url_new = data.android_url;
        this.appstore_url_new = data.appstore_url;
      }
    },
    async getStaticChainInfo() {
      await apiGetStaticTableInfo(7).then(({ data }) => {
        this.currencyMaps = data;
      });
    },
    onChangeStyle() {
      if (this.darkMode == 1) {
        this.changeDarkMode(2);
      } else {
        this.changeDarkMode(1);
      }
    },
    async getCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.currencyMaps = JSON.parse(JSON.stringify(data));
      }
    },
    goQuicklycoins() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "quicklycoins"),
        });
      }
    },
    onCustomerServiceClick() {
      this.$emit("onApiClick");
    },
    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
      this.langVisible = false;
    },
    handleSelect(key) {
      let command = "zh_CN";
      if (key == "zh_CN") {
        command = "zh_CN";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "zh_HK") {
        command = "zh_HK";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "en") {
        command = "en";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ja") {
        command = "ja";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ko") {
        command = "ko";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onMaskCurrencyDialog() {
      this.activeName = "second";
      this.onMaskLangDialog();
    },
    onShowLangDialog() {
      this.activeName = "first";
      this.onMaskLangDialog();
    },
    onMaskLangDialog() {
      this.langVisible = true;
      this.reportTabClick("language", "");
    },
    onLangClick(item) {
      try {
        const currentLang = this.language;
        this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
        const langPackage = getLangPackage([item.value]);
        this.languageName = item.name;

        this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
        this.changeLanguage(item.value);
        this.$i18n.locale = item.value;
        this.langVisible = false;

        const url = window.location.href;
        const lastSlashIndex = url.lastIndexOf("/");
        const result = url.substring(0, lastSlashIndex);
        const queryParams = window.location.href.split("?")[1];
        // 必须删除之前的，不然会造成sessionStorage 超过容量，报错
        localStorage.removeItem(`lang_${currentLang}`);
        if (queryParams) {
          window.location.href = `${result}/${this.language}?${queryParams}`;
        } else {
          window.location.href = `${result}/${this.language}`;
        }
      } catch (e) {
        console.error(e);
      }
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    reportParams(origin) {
      this.$sensors.track("App_Download", {
        download_entrance: this.downloadOrigin,
        download_click_type: origin,
        invite_code: "",
      });
    },
    reportTabClick(first_level, second_level) {
      this.$sensors.track("Tab_Click", {
        of_module: "top",
        first_level_tab: first_level,
        second_level_tab: second_level,
      });
    },
  },
};
</script>
<style lang="scss">
.el-scrollbar:not(.zh_CN) {
  .page-header-8coin {
    min-width: 1550px; //1550px;
  }
}
.el-scrollbar.ja {
  .page-header-8coin {
    min-width: 1432px; //1550px;
  }
}
.el-scrollbar.vi {
  .page-header-8coin {
    min-width: 1558px; //1550px;
  }
}
.el-scrollbar.hi {
  .page-header-8coin {
    min-width: 1358px; //1550px;
  }
}
.el-scrollbar.th {
  .page-header-8coin {
    min-width: 1450px; //1550px;
  }
}
.el-scrollbar.ko {
  .page-header-8coin {
    min-width: 1308px; //1550px;
  }
}
.el-scrollbar.zh_CN {
  .page-header-8coin {
    min-width: 1220px;
  }
}

.ar {
  .page-header-8coin {
    direction: ltr !important;
  }

  .page-header-8coin {
    .right-menus .el-menu .download-app {
      width: 130px;
    }
  }

  .user-center-menu.el-menu-item.nav-menu-item.login
    .el-menu--horizontal.sub-item-wrap.select-user-item
    .el-menu
    .el-menu-item {
    text-align: right;
  }
}
</style>
<style lang="scss">
/* yuan language innerBody */
//修改Lang宽度
.select-lang-masks {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;

  &:hover {
    opacity: 1;
  }

  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }
  &.currency span {
    font-family: "Open Sans";
  }

  .expand {
    width: 16px;
    height: 16px;
    margin-left: 1px;
    user-select: none;
  }
}

.el-menu--horizontal.sub-item-wrap.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }

  &.V2 {
    margin-right: 20px;
  }

  top: 59px !important;

  &.merchant-item {
    width: 135px;
  }

  .el-menu {
    background-color: #fff;
    width: 130px !important;
    min-width: 130px !important;

    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;

      span {
        margin-left: 0px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

//修改货币宽度
.el-menu--horizontal.sub-item-wrap.select-fiat-currency {
  &.merchant-item {
    width: 135px;
  }

  .el-menu {
    background-color: #fff;

    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;

      &:hover {
        background-color: #f3f7fc;
      }

      span {
        margin-left: 0px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

.select-fiat-currency {
  top: 59px !important;

  &.el-menu--horizontal {
    width: 140px !important;
  }

  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;

  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}

.page-header-8coin {
  user-select: none;
  height: 64px;
  background-color: #0d1327;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  /* min-width: 1600px; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  .logo {
    width: 150px;
    height: 34px;
    display: block;

    &.isMaskEx {
      width: 122px;
      height: 24px;
    }
  }

  .langVisible_n {
    z-index: 999;
    width: 840px;
    margin-top: 29vh !important;

    .close_icon {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 28px;
      right: 30px;
      z-index: 10;
      cursor: pointer;
    }

    .el-dialog__header {
      height: 0px;
      padding: 0 !important;
    }

    .el-dialog__body {
      padding-top: 16px;

      .el-tabs__header {
        margin-bottom: 22px;
      }

      .el-tabs__active-bar {
        background-color: #165ad3;
      }

      .el-tabs__item {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 12px;
      }

      .pane-title {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 10px;
      }
    }

    ul {
      margin-left: 6px;
    }

    li {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      width: 23%;
      margin-right: 1%;
      padding-left: 1%;

      &:hover {
        background-color: #f7f8f9;
        color: black;
      }

      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #202630;
      }

      .red-lang-curr {
        color: #165ad3;
      }
    }
  }

  .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    margin-top: 0;
  }

  .left-pages,
  .right-menus,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .left-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .pages-routes {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 35px;

      li {
        color: #fff;
        height: 64px;
        font-size: 14px;
        font-weight: 400;
        line-height: 64px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #acb9db;
        position: relative;

        a {
          color: #fff;
          font-size: 14px;
          text-decoration: none;
          height: 100%;
          display: flex;
          align-items: center;
        }

        &.none-active {
          width: 100%;
          border-bottom: 3px solid #165ad3;
          color: #fff;
          font-weight: 500;
        }

        &:hover {
          color: #fff;

          a {
            color: #fff;
            opacity: 1;
          }
        }

        &.active {
          &:after {
            content: "";
            position: absolute;
            background-color: #165ad3;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 2px;
          }
        }
      }

      .normal-link {
        padding: 0px;

        a {
          padding: 0 20px;
        }
      }
    }

    .li-sub-menu {
      margin-top: -2px;
      position: absolute;
      z-index: 666;
      padding: 15px;

      min-width: 360px;
      border-radius: 2px;
      box-shadow: 0 0 10px 0 rgba(53, 64, 82, 0.2);
      border-radius: 0 0 8px 8px;
      background: #0d1327;

      .it {
        cursor: pointer;
        padding: 20px 20px;
        display: flex;
        align-items: center;

        min-width: 330px;
        min-height: 74px;

        border-radius: 4px;

        .i {
          width: 32px;
          height: 32px;
        }

        .t-st {
          display: flex;
          flex-direction: column;

          .t {
            margin-left: 20px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
          }

          .st {
            margin-left: 20px;
            opacity: 0.52;
            font-size: 12px;
            font-weight: 500;
            color: #8b909b;
          }
        }

        .act {
          opacity: 0;
          font-size: 16px;
          font-weight: 600;
          margin-left: auto;
          color: #165ad3;
          transition: all 0.1s linear;
        }

        &:hover {
          background-color: rgba(70, 90, 255, 0.05); // rgba(32, 38, 48, 0.06);

          .act {
            opacity: 1;
          }
        }
      }
    }

    .icon-top {
      margin-left: 2px;
      display: inline-block;
      transition: transform 0.5s;
    }
  }

  .app-link {
    font-size: 14px;
  }

  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: #12161c !important;
  }

  .right-menus {
    margin-right: 20px;
    height: 60px;

    .quick-access {
      padding-left: 20px;
      cursor: pointer;

      a {
        color: white;
      }
    }

    .vertical-line-li {
      position: relative;
      float: left;

      .vertical-line {
        height: 22px;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        display: inline-block;
        margin-top: 20px;
        margin-right: 18px;
      }
    }

    .customer-service {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: #ffffff;
      }
    }

    .station-name {
      color: red;
      font-size: 24px;
      margin-right: 10px;
    }

    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #acb9db;
    }

    .el-menu {
      background-color: #0d1327;
      text-align: left;

      .letter-center {
        float: left;
        width: 40px;
        line-height: 22px;
        margin-top: 20px;
        position: relative;

        img {
          width: 22px;
          height: 22px;
          top: 0px;
          cursor: pointer;
        }

        .driver {
          position: absolute;
          background-color: rgb(172, 185, 219, 0.3);
          right: 0px;
          top: -2px;
          width: 1px;
          height: 30px;
        }
      }

      .download-app {
        float: left;
        position: relative;
        width: fit-content;
        height: 60px;
        outline: none;
        border-radius: 4px;
        &.theme {
          margin-left: 18px;
        }
        .download-app-text {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          padding: 0 0;
          margin-right: 20px;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            color: #ffffff;
            opacity: 1;
          }

          .down_icon {
            width: 22px;
            height: 22px;
            margin-right: 5px;
            user-select: none;
          }
        }

        .down-box {
          box-sizing: border-box;
          height: 200px;
          white-space: nowrap;
          padding: 20px 20px 30px;
          border-radius: 2px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
          display: block;
          position: absolute;
          right: 0px;
          z-index: 3000;

          .down-load-text {
            font-size: 16px;
            font-weight: 400;
            color: #202630;
            overflow: hidden;
            max-width: 380px;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            .icon-title {
              width: 28px;
              height: 28px;
            }
          }

          .down-load-content {
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            text-align: center;

            .text-title {
              margin-bottom: 10px;
            }

            img {
              width: 70px;
              height: 70px;
            }
          }

          .img-content {
            display: flex;
            margin-top: 20px;

            .down-load-url-outer {
              height: 110px;
              width: 110px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;

              .down-load-url {
                height: 100px;
                width: 100px;
              }
            }

            .left-item {
              padding-top: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            }

            .down-load-item-text {
              display: flex;
              align-items: center;
              flex-shrink: 0;
              height: 20px;
              margin-left: 15px;
              font-size: 14px;
              color: #202630;

              .icon-title {
                width: 28px;
                height: 28px;
                vertical-align: middle;
                margin-right: 7px;
              }
            }

            .quesiton {
              margin-left: 15px;
              padding: 6px 15px;
              border-radius: 16px;
              background-color: rgba(32, 38, 48, 0.1);
              font-size: 12px;
              color: #202630;
            }

            .down-load-img {
              width: 59px;
              height: 59px;
            }
          }
        }
      }

      .select-fiat {
        margin-right: 20px;
      }

      &.el-menu--horizontal {
        border-bottom: none;
      }

      .sub-item-child {
        color: red;

        #sub-item-asset & {
          &:hover {
            a {
              color: #000;
            }
          }
        }

        &.el-submenu {
          &:hover {
            a {
              color: #000;
            }
          }
        }

        .el-submenu__title {
          display: inline-block;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;

          .sub-item-child-jump:hover {
            color: #000;
          }

          a:hover {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 14px;
            display: inline-block;
          }
        }
      }

      .el-submenu {
        &:hover {
          a {
            color: #ffffff;
          }

          i {
            color: #ffffff;
          }
        }

        a {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          line-height: 14px;

          &:hover {
            opacity: 1;
          }
        }

        .el-menu--horizontal {
          &.merchant-item {
            width: 135px;
          }

          .el-menu {
            background-color: #fff;

            .el-menu-item {
              height: 40px;
              line-height: 40px;

              &:hover {
                background-color: #f3f7fc;
              }

              &.hide-icon {
                i {
                  visibility: hidden;
                }
              }

              i {
                margin-right: 10px;
                position: relative;
                top: -1px;
                color: inherit;
              }

              &.is-active {
                background-color: #fff !important;
              }
            }
          }
        }

        //修改Lang宽度
        .el-menu--horizontal.sub-item-wrap.select-lang {
          top: 59px !important;

          &.merchant-item {
            width: 135px;
          }

          .el-menu {
            background-color: #fff;
            width: 130px !important;
            min-width: 130px !important;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;

              span {
                margin-left: 0px;

                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        //修改货币宽度
        .el-menu--horizontal.sub-item-wrap.select-fiat-currency {
          &.merchant-item {
            width: 135px;
          }

          .el-menu {
            background-color: #fff;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;

              &:hover {
                background-color: #f3f7fc;
              }

              span {
                margin-left: 0px;

                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }

      .no-arrow {
        .el-submenu__icon-arrow {
          display: none;
        }
      }

      .el-submenu__title {
        border-bottom: 0;
        padding: 0;

        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 60px;
          box-sizing: border-box;

          &.none-active {
            color: #fff;
            background-color: #12161c;

            &:after {
              content: "";
              height: 3px;
              width: 50px;
              background-color: #165ad3;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &:hover {
            color: #fff;
            background-color: #12161c;
          }
        }
      }

      .el-submenu__title:hover {
        background-color: #12161c;
        text-align: left;
        color: #ffffff;

        a {
          text-decoration: none;
        }
      }

      .nav-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;

        &.login {
          a {
            color: #ffffff;

            &.none-active {
              color: #fff;
              opacity: 1;
              background-color: #12161c;
            }

            &:hover {
              color: #fff;
              opacity: 1;
            }
          }
        }

        .driver {
          position: absolute;
          right: -40px;
          top: 0px;
          width: 1px;
          height: 30px;
          margin: 0 10px 0 9px;
          background-color: transparent;
        }

        &.register {
          position: relative;
          margin-right: 20px;
          /* background-color: #165ad3; */
          height: 30px;
          margin-top: 15px;
          line-height: 30px;
          border-radius: 2px !important;
          border-right: 1px;
          border-right: 1px soild #acb9db;

          .none-active {
            border-radius: 2px;
          }

          &:hover {
            background: rgba(22, 90, 211, 0.8);
            color: #165ad3;
          }

          a {
            line-height: 30px;
            color: white;
            border-radius: 4px;
            background: var(--homeBtnBgColor);
            border-radius: 100px;
            &.none-active {
              color: #fff;
              background-color: #165ad3;
            }
          }
        }

        &:hover {
          background-color: #12161c !important;
          color: #165ad3;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          text-decoration: none;
          color: #acb9db;

          &.none-active {
            color: #fff;
          }
        }
      }
    }

    .lang {
      img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 10px;
        filter: brightness(200);
        -webkit-filter: brightness(150%);
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    background: rgba(216, 216, 216, 1);
    opacity: 0.18;
    position: relative;
    top: 20px;
  }
}

.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }

  &.V2 {
    margin-right: 20px;
  }
}

.select-fiat-currency {
  top: 59px !important;

  &.el-menu--horizontal {
    width: 140px !important;
  }

  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;

  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}
</style>
