<template>
  <div>
    <div class="ad-panel-feature__container">
      <div class="ad-panel-feature__container-items">
        <div
          class="ad-panel-feature__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="ad-panel-feature__container-items-item-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div>
            <div class="ad-panel-feature__container-items-item-title">
              {{ translateTitle(item.title) }}
            </div>
            <div class="ad-panel-feature__container-items-item-subTitle">
              {{ translateTitle(item.subTitle) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import ad1 from "@/assets/img/home/ad/ad1.png";
import ad2 from "@/assets/img/home/ad/ad2.png";
import ad3 from "@/assets/img/home/ad/ad3.png";
import ad4 from "@/assets/img/home/ad/ad4.png";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      items: [
        {
          title: "一键跟单",
          subTitle: "财富不落单",
          icon: ad1,
          // src/assets/img/home/ad/ad1.png
        },
        {
          title: "投资赚币",
          subTitle: "持币生币 理财有道",
          icon: ad2,
        },
        {
          title: "借贷质押 灵活满足",
          subTitle: "小资金撬动大收益",
          icon: ad3,
        },
        {
          title: "4E金融卡",
          subTitle: "前途无界 触手可及",
          icon: ad4,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.ad-panel-feature__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 498px; */
  background-color: var(--homeBgLightColor);
  &.banner {
    background-image: url(../../assets/img/home/banners/top-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  color: var(--homeFontColor);
  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
    height: 500px;
    /* background-image: url("../../../assets/images/home/ad-banner.png");
    background-size: cover;
    background-position: center; */
    &-title {
      font-size: 48px;
      font-weight: 500;
      line-height: 60px;
    }
    &-tips {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  &-items {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 1200px;
    min-height: 488px;
    flex-flow: row wrap;
    padding: 100px 0;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(50% - 16px);
      border-radius: 12px;
      border: var(--homeGrayBorder);
      cursor: auto;
      padding: 24px;
      &:hover {
        background: var(--homeHoverBgColor);
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 32px;
      }
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }
      /* height: 100%; */
      &-icon {
        /* width: 100px;
        height: 100px; */
        /* background-color: var(--homeBgLightColor);
        border-radius: 50%; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 32px;
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        img {
          width: 80px;
          height: 80px;
        }
      }
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 125% */
        margin-bottom: 12px;
      }
      &-subTitle {
        color: var(--homeLightGary);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        text-align: left;
      }
    }
  }

  &-title {
    font-size: 36px;
    font-weight: 600;
    margin-top: 50px;
  }
}
</style>